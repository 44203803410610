/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { YT_THUMB_URL } from '../../../../config';
import { durationCalc, formatViewValue } from '../../../../utils';

export default function SearchItem({
  title,
  videoId,
  channel,
  duration,
  views,
  changeMusicVideo,
  t,
}) {
  return (
    <div className="col-sm-4 col-lg-3">
      <div
        css={css`
          @media only screen and (max-width: 600px) {
            flex-direction: row;
            .album__cover {
              min-width: 150px;
              max-width: 150px;
              height: 90px;
              border-radius: 6px;
              img {
                width: 100%;
              }
            }
          }
        `}
        className="album"
      >
        <div
          css={css`
            position: relative;
          `}
          className="album__cover"
        >
          <Link className="search-item-link" to={`/music?vid=${videoId}`}>
            <img
              className="search-item-img"
              src={YT_THUMB_URL + videoId + '/mqdefault.jpg'}
              alt=""
            />
          </Link>
          <span
            css={css`
              position: absolute;
              bottom: 8px;
              right: 8px;
              padding: 2px 6px;
              color: rgb(255, 255, 255);
              background-color: rgba(0, 0, 0, 0.5);
              font-size: 13px;
              border-radius: 2px;
              z-index: 1;
            `}
          >
            {durationCalc(duration / 1000)}
          </span>
          <div
            className="play-icon"
            css={css`
              @media only screen and (max-width: 600px) {
                width: 30px;
                height: 30px;
                border-radius: 4px;
                svg {
                  width: 20px;
                }
              }
            `}
            onClick={() =>
              changeMusicVideo([
                {
                  vid: videoId,
                  title,
                  channel,
                },
              ])
            }
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
            </svg>
          </div>
          <span
            css={css`
              @media only screen and (max-width: 600px) {
                display: none;
              }
            `}
            className="album__stat"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M20,13.18V11A8,8,0,0,0,4,11v2.18A3,3,0,0,0,2,16v2a3,3,0,0,0,3,3H8a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H6V11a6,6,0,0,1,12,0v2H16a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h3a3,3,0,0,0,3-3V16A3,3,0,0,0,20,13.18ZM7,15v4H5a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1Zm13,3a1,1,0,0,1-1,1H17V15h2a1,1,0,0,1,1,1Z" />
              </svg>{' '}
              {formatViewValue(views)}
            </span>
          </span>
        </div>
        <div
          css={css`
            @media only screen and (max-width: 600px) {
              margin-left: 8px;
              width: calc(100% - 150px);
              h3 {
                font-size: 14px;
              }
            }
          `}
          className="album__title"
        >
          <h3>
            <Link to={`/music?vid=${videoId}`}>{title}</Link>
          </h3>
          <span>{channel}</span>
          <span>
            {formatViewValue(views)} {t('SearchPage.views')}
          </span>
        </div>
      </div>
    </div>
  );
}
