/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import _ from 'lodash';
import CircleLoading from '../../Shared/CircleLoading';
import VideoItem from './VideoItem';
import VideoList from './VideoList';

export default function SuggestedVideos({ suggestedVideos, t }) {
  function renderVideoItems() {
    return _.map(suggestedVideos, (item) => (
      <VideoItem key={item.vid} t={t} {...item} />
    ));
  }
  return (
    <div className="col-xl-4 col-lg-5">
      <div
        className="relative-music"
        css={css`
          position: relative;
          @media only screen and (max-width: 600px) {
            margin-top: 20px;
          }
        `}
      >
        {!suggestedVideos.length ? (
          <CircleLoading
            showLoading
            css={css`
              position: relative;
              height: 280px;
              background-color: transparent;
            `}
          />
        ) : (
          <VideoList>{renderVideoItems()}</VideoList>
        )}
      </div>
    </div>
  );
}
