/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { userAuth } from '../actions';
import * as types from '../actions/types';
import { auth, generateUserDocument } from '../services/firebaseConfig';

export const AuthContext = React.createContext();

const INITIAL_STATE = {};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_USER_SUCCESS:
      return { ...action.payload };
    default:
      throw new Error('Unexpected action');
  }
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

function AuthProvider({ children }) {
  const [user, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { pathname } = useLocation();

  useEffect(() => {
    auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await auth().currentUser.getIdToken(true);
        localStorage.setItem('token', token);
        const { uid, displayName, photoURL } = user;
        const userInfo = await generateUserDocument({
          uid,
          displayName,
          photoURL,
        });
        dispatch(userAuth(userInfo));
        if (pathname === '/signin') {
          window.location.href = '/';
        }
      }
    });
  }, [pathname]);

  return (
    <AuthContext.Provider
      value={{
        user,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
