/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

export default function NewReleaseHeading({ heading }) {
  return (
    <div className="col-12 col-md-6">
      <div
        css={css`
          margin-top: 0px;
        `}
        className="main__title main__title--page"
      >
        <h1>{heading}</h1>
      </div>
    </div>
  );
}
