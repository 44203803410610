/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { changeMusicVideo, setPlaying } from '../actions';
import { api } from '../api';
import MusicGenreInfo from '../components/ArtistDetailsComponents/ArtistInfos';
import MusicWrapper from '../components/HomePageComponents/NewWrapper';
import MusicItem from '../components/HomePageComponents/NewWrapper/NewItem';
import MusicList from '../components/HomePageComponents/NewWrapper/NewList';
import Breadcrumb from '../components/Shared/BreadCrumb';
import CircleLoading from '../components/Shared/CircleLoading';
import Layout from '../components/Shared/Layout';
import { DEFAULT_PAGE_ITEM_LIMIT } from '../config';
import { AudioContext } from '../contexts/AudioContext';

export default function MusicGenreDetailsPage() {
  const { dispatch } = useContext(AudioContext);
  const [genreInfos, setGenreInfos] = useState({});
  const [musics, setMusics] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();
  const { id, name } = useParams();

  function handleChangeMusicVideo(musics) {
    dispatch(changeMusicVideo(musics));
    dispatch(setPlaying(true));
  }

  function renderMusicItems() {
    return musics.map(({ videoId, channel, title, duration, views }) => (
      <MusicItem
        key={videoId}
        changeMusicVideo={handleChangeMusicVideo}
        {...{
          Channel: channel,
          Name: title,
          Vid: videoId,
          Views: views,
          Duration: duration,
        }}
      />
    ));
  }

  async function handleLoadMore() {
    try {
      const response = await api.getSearchResults(
        name,
        DEFAULT_PAGE_ITEM_LIMIT + pageIndex * 12,
      );
      setMusics(response.infos);
      setPageIndex(pageIndex + 1);
      if (response.infos.length < musics.length + 12) {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getGenreInfos() {
      try {
        const response = await api.getGenreInfos(id);
        const { Genre, url } = response.data;
        setGenreInfos({
          Name: Genre,
          url,
        });
      } catch (error) {
        console.log(error);
      }
    }
    getGenreInfos();
  }, [id]);

  useEffect(() => {
    async function getMusics() {
      try {
        const response = await api.getSearchResults(
          name,
          DEFAULT_PAGE_ITEM_LIMIT,
        );
        setMusics(response.infos);
      } catch (error) {
        console.log(error);
      }
    }
    getMusics();
  }, [name]);

  useEffect(() => {
    document.title = `${t('GenresDetailsScreen.music_from', {
      genre: name,
    })} - ${t('HomeScreen.app_name')}`;
  }, []);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('GenresDetailsScreen.genre'),
      path: '/genres',
    },
    {
      name,
      path: '',
    },
  ];

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
      </div>
      <br />
      <MusicGenreInfo
        subInfo={t('GenresDetailsScreen.subInfo')}
        {...genreInfos}
      />
      <InfiniteScroll
        dataLength={musics.length}
        next={handleLoadMore}
        hasMore={hasMore}
        loader={
          <CircleLoading
            showLoading
            css={css`
              position: relative;
              height: 280px;
              background-color: transparent;
            `}
          />
        }
      >
        <MusicWrapper
          style={{ marginTop: 30 }}
          heading={t('GenresDetailsScreen.music_from', {
            genre: name,
          })}
          isDetailsListing
        >
          <MusicList>{renderMusicItems()}</MusicList>
        </MusicWrapper>
      </InfiniteScroll>
    </Layout>
  );
}
