import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../components/ContactComponents/ContactForm';
import ContactInfos from '../components/ContactComponents/ContactInfos';
import ContactWrapper from '../components/ContactComponents/ContactWapper';
import Breadcrumb from '../components/Shared/BreadCrumb';
import Layout from '../components/Shared/Layout';

export default function ContactPage() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t('HomeScreen.contact_name')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('ContactScreen.contact'),
      path: '',
    },
  ];

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
      </div>
      <ContactWrapper>
        <ContactForm />
        <ContactInfos />
      </ContactWrapper>
    </Layout>
  );
}
