import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@material-ui/core';
import { CircleSlider } from 'react-circle-slider';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import TimerIcon from '@mui/icons-material/Timer';
import { css, jsx } from '@emotion/react';
import Button from '@mui/material/Button';

export default function SleepTimer() {
  const [open, setOpen] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [isTimerSet, setIsTimerSet] = useState(true);
  const [sliderValue, setSliderValue] = useState(20);
  const [time, setTime] = useState(sliderValue);

  const handleReset = () => {
    setTime(0);
    setSliderValue(0);
    setOpen(false);
  };
  const handleSetTime = () => {
    setTime(sliderValue);
    setOpen(false);
  };
  return (
    <>
      {isTimerSet ? (
        <div
          className="sleep__timer_button"
          onClick={() => {
            setOpen(true);
          }}
        >
          <TimerIcon />
          <span>Sleep Timer</span>
        </div>
      ) : (
        <TimerOffIcon onClick={() => setOpen(true)}>off</TimerOffIcon>
      )}
      <Dialog
        style={{ zIndex: 1400 }}
        className="dialog__timer"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography
          variant="h5"
          color="primary"
          align="center"
          style={{ marginTop: '10px' }}
        >
          Pause Music After
        </Typography>
        <div className="container">
          <div className="textContainer">
            {sliderValue}
            <div className="minute">MINUTES</div>
          </div>
          <CircleSlider
            value={sliderValue}
            stepSize={5}
            onChange={(value) => setSliderValue(value)}
            size={300}
            max={90}
            gradientColorFrom="#00ec6a"
            gradientColorTo="#c3fc67"
            knobRadius={25}
            progressWidth={30}
            circleWidth={30}
          />
        </div>
        <div className="time_options">
          <div
            variant="contained"
            color="success"
            className="time_options-item"
            onClick={() => setSliderValue(10)}
          >
            10
          </div>
          <div
            variant="contained"
            color="success"
            className="time_options-item"
            onClick={() => setSliderValue(20)}
          >
            20
          </div>
          <div className="time_options-item" onClick={() => setSliderValue(30)}>
            30
          </div>
          <div className="time_options-item" onClick={() => setSliderValue(60)}>
            60
          </div>
          <div className="time_options-item" onClick={() => setSliderValue(90)}>
            90
          </div>
        </div>

        <div className="dialog__actions__timer">
          {/* <FormControlLabel
            value="Always On"
            control={
              <Switch
                checked={checkbox}
                onChange={(e) => setCheckbox(e.target.checked)}
                value="checked always on"
                color="primary"
              />
            }
            label="ALWAYS"
            labelPlacement="end"
            style={{ color: '#e91e63' }}
          /> */}
          <Button
            className="timer__button start"
            variant="contained"
            color="success"
            onClick={() => handleSetTime()}
          >
            Start
          </Button>
          <div className="seperate" />
          <Button
            color="success"
            className="timer__button reset"
            variant="contained"
            onClick={() => handleReset()}
          >
            Cancle
          </Button>
        </div>
      </Dialog>
    </>
  );
}
