import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../api';
import AlbumWrapper from '../components/HomePageComponents/AlbumWrapper';
import AlbumItem from '../components/HomePageComponents/AlbumWrapper/AlbumItem';
import AlbumList from '../components/HomePageComponents/AlbumWrapper/AlbumList';
import Sliders from '../components/HomePageComponents/Slides';
import Breadcrumb from '../components/Shared/BreadCrumb';
import Layout from '../components/Shared/Layout';
import { DEFAULT_PAGE_ITEM_LIMIT } from '../config';

export default function AlbumListPage() {
  const { t } = useTranslation();
  const [slides, setSlides] = useState([]);
  const [albums, setAlbums] = useState([]);

  function renderAlbums() {
    return albums.map((item) => <AlbumItem key={item.id} {...item} />);
  }

  useEffect(() => {
    async function getAlbums() {
      try {
        const response = await api.getAlbums(DEFAULT_PAGE_ITEM_LIMIT);
        setAlbums(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getAlbums();
  }, []);

  useEffect(() => {
    async function getSlides() {
      try {
        const response = await api.getSlides();
        setSlides(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getSlides();
  }, []);

  useEffect(() => {
    document.title = `${t('AlbumScreen.heading')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('AlbumScreen.heading'),
      path: '',
    },
  ];

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
      </div>
      <br />
      <Sliders slides={slides} />
      <AlbumWrapper isDetailsListing>
        <AlbumList>{renderAlbums()}</AlbumList>
      </AlbumWrapper>
    </Layout>
  );
}
