import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import * as types from '../actions/types';

export const ModalContext = React.createContext();

const INITIAL_STATE = {
  isOpen: false,
  component: null,
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        isOpen: true,
        component: action.payload,
      };
    case types.HIDE_MODAL:
      return {
        isOpen: false,
        component: null,
      };
    default:
      throw new Error('Unexpected action');
  }
}

ModalProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

function ModalProvider({ children }) {
  const [modal, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <ModalContext.Provider value={{ modal, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
