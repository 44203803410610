/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import { changeMusicVideo, setPlaying } from '../actions';
import { api } from '../api';
import SearchWrapper from '../components/SearchPageComponents/SearchWrapper';
import SearchItem from '../components/SearchPageComponents/SearchWrapper/SearchItem';
import SearchList from '../components/SearchPageComponents/SearchWrapper/SearchList';
import Breadcrumb from '../components/Shared/BreadCrumb';
import CircleLoading from '../components/Shared/CircleLoading';
import Layout from '../components/Shared/Layout';
import { DEFAULT_PAGE_ITEM_LIMIT } from '../config';
import { AudioContext } from '../contexts/AudioContext';
import { getQueryString } from '../utils';

export default function SearchPage() {
  const { dispatch } = useContext(AudioContext);
  const [musics, setMusics] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const location = useLocation();
  const { keyword } = getQueryString(location.search);
  const { t } = useTranslation();

  function handleChangeMusicVideo(musics) {
    dispatch(changeMusicVideo(musics));
    dispatch(setPlaying(true));
  }

  function renderSearchItems() {
    return musics.map((item) => (
      <SearchItem
        key={item.videoId}
        t={t}
        changeMusicVideo={handleChangeMusicVideo}
        {...item}
      />
    ));
  }

  async function handleLoadMore() {
    try {
      const response = await api.getSearchResults(
        keyword,
        DEFAULT_PAGE_ITEM_LIMIT + pageIndex * 8,
      );
      setMusics(response.infos);
      setPageIndex(pageIndex + 1);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    document.title = `${title} - ${t('HomeScreen.app_name')}`;
  }, []);

  useEffect(() => {
    async function getNewMusics() {
      try {
        setMusics([]);
        const response = await api.getSearchResults(
          keyword,
          DEFAULT_PAGE_ITEM_LIMIT,
        );
        setMusics(response.infos);
      } catch (error) {
        console.log(error);
      }
    }
    getNewMusics();
  }, [keyword]);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('HomeScreen.search_name'),
      path: '',
    },
  ];

  const title = t('SearchPage.search_heading', { keyword });

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
      </div>
      <InfiniteScroll
        dataLength={musics.length}
        next={handleLoadMore}
        hasMore
        loader={
          <CircleLoading
            showLoading
            css={css`
              position: relative;
              height: 280px;
              background-color: transparent;
            `}
          />
        }
      >
        <SearchWrapper title={title} keyword={keyword}>
          <SearchList>{renderSearchItems()}</SearchList>
        </SearchWrapper>
      </InfiniteScroll>
    </Layout>
  );
}
