/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import SuggestedVideos from './SuggestedVideos';
import VideoInfos from './VideoInfos';

export default function MusicDetailsComponents({
  videoInfos,
  changeMusicVideo,
  suggestedVideos,
}) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        margin-top: 0;
      `}
      className="music-details"
    >
      <div className="row">
        <VideoInfos t={t} changeMusicVideo={changeMusicVideo} {...videoInfos} />
        <SuggestedVideos t={t} suggestedVideos={suggestedVideos} />
      </div>
    </div>
  );
}
