/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../contexts/AuthContext';
import { auth } from '../../../services/firebaseConfig';

export default function ProfileWrapper() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  async function handleLogout() {
    await auth().signOut();
    window.location.href = '/';
  }

  return (
    <div
      css={css`
        margin: 50px 0 0 20px;
      `}
      className="profile-information"
    >
      <div className="profile__user">
        <div className="profile__avatar">
          <img
            src={require('../../../assets/images/avatar.svg').default}
            alt=""
          />
        </div>
        <div className="profile__meta">
          <span
            css={css`
              margin-bottom: 7px;
            `}
          >
            {t('ProfileScreen.my_account')}
          </span>
          <h3>{user.displayName}</h3>
        </div>
      </div>
      <button
        css={css`
          margin-top: 20px;
        `}
        onClick={handleLogout}
        className="profile__logout"
        type="button"
      >
        <span>{t('Common.btn_sign_out')}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z" />
        </svg>
      </button>
    </div>
  );
}
