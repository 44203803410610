/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function PlayingQueue({ name, path, children }) {
  const { t } = useTranslation();

  return (
    <div className="col-12 col-lg-6">
      <div
        css={css`
          background-color: #16151a;
          margin-top: 20px;
          border-radius: 12px;
          overflow: hidden;
          border: 1px solid #222227;
        `}
        className="dashbox"
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid #222227;
          `}
          className="dashbox__title"
        >
          <h3
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              color: #fff;
              font-weight: 400;
              font-size: 20px;
              line-height: 100%;
              margin-bottom: 0;
              svg {
                fill: #25a56a;
                margin-right: 10px;
                width: 22px;
                height: auto;
              }
            `}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z" />
            </svg>
            {name}
          </h3>
          {path ? (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
              `}
              className="dashbox__wrap"
            >
              <Link
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  width: 22px;
                  height: 30px;
                  > svg {
                    fill: #c0c0c0;
                    width: 18px;
                    height: auto;
                    transition: fill 0.5s ease;
                    &:hover {
                      fill: #25a56a;
                    }
                  }
                `}
                className="dashbox__refresh"
                to={path}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1H8.62a1,1,0,0,0,0-2H6.22A8,8,0,1,1,4,12a1,1,0,0,0-2,0A10,10,0,1,0,12,2Zm0,6a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1h2a1,1,0,0,0,0-2H13V9A1,1,0,0,0,12,8Z" />
                </svg>
              </Link>
              <Link
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  font-size: 13px;
                  margin-left: 15px;
                  color: #c0c0c0;
                `}
                className="dashbox__more"
                to={path}
              >
                {t('Common.view_all')}
              </Link>
            </div>
          ) : null}
        </div>
        <div
          css={css`
            padding: 20px;
            position: relative;
            display: block;
            width: 100%;
          `}
          className="dashbox__list-wrap"
        >
          <ul className="main__list main__list--dashbox">{children}</ul>
        </div>
      </div>
    </div>
  );
}
