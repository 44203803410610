/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { hideModal } from '../../../actions';
import { ModalContext } from '../../../contexts/ModalContext';

const bodyTag = document.querySelector('body');
function ModalPortal() {
  const {
    modal: { isOpen, component },
    dispatch: modalDispatch,
  } = useContext(ModalContext);
  let elm = document.createElement('div');
  elm.setAttribute('id', 'modal-root');

  useEffect(() => {
    bodyTag.insertBefore(elm, bodyTag.childNodes[0]);
    return () => {
      bodyTag.removeChild(elm);
    };
  }, [elm]);

  return (
    elm &&
    ReactDOM.createPortal(
      isOpen ? (
        <>
          {component}
          <div
            css={css`
              background-color: rgb(0 0 0 / 60%);
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1010;
            `}
            onClick={() => modalDispatch(hideModal())}
            className="bg-modal"
          />
        </>
      ) : null,
      elm,
    )
  );
}

export default ModalPortal;
