import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ContactWrapper({ children }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="row row--grid">
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>{t('ContactScreen.contact')}</h1>
          </div>
        </div>
      </div>
      <div className="row row--grid">{children}</div>
    </>
  );
}
