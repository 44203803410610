/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Fragment } from 'react';
import NewReleaseFilter from '../NewReleaseFilter';
import NewReleaseHeading from '../NewReleaseHeading';

export default function NewReleaseWrapper({
  children,
  heading,
  handleSortList,
  sorts,
}) {
  return (
    <Fragment>
      <div
        css={css`
          margin-top: 50px;
        `}
        className="row row--grid"
      >
        <NewReleaseHeading heading={heading} />
        <NewReleaseFilter sorts={sorts} handleSortList={handleSortList} />
      </div>
      <div className="row row--grid">{children}</div>
    </Fragment>
  );
}
