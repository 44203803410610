/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { changeMusicVideo, setPlaying } from '../actions';
import { api } from '../api';
import FeatureItem from '../components/HomePageComponents/NewWrapper/NewItem';
import FeaturedList from '../components/HomePageComponents/NewWrapper/NewList';
import Sliders from '../components/HomePageComponents/Slides';
import FeaturedWrapper from '../components/NewReleaseComponents/NewReleaseWrapper';
import Breadcrumb from '../components/Shared/BreadCrumb';
import CircleLoading from '../components/Shared/CircleLoading';
import Layout from '../components/Shared/Layout';
import {
  DEFAULT_FEATURED_SORT,
  DEFAULT_PAGE_ITEM_LIMIT,
  DEFAULT_PAGE_ITEM_START_INDEX,
} from '../config';
import { AudioContext } from '../contexts/AudioContext';

export default function FeaturedPage() {
  const { dispatch } = useContext(AudioContext);
  const { t } = useTranslation();
  const [slides, setSlides] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [sortValue, setSortValue] = useState(DEFAULT_FEATURED_SORT);

  function handleChangeMusicVideo(musics) {
    dispatch(changeMusicVideo(musics));
    dispatch(setPlaying(true));
  }

  function renderFeaturedItems() {
    return featured.map((item) => (
      <FeatureItem
        key={item.id}
        changeMusicVideo={handleChangeMusicVideo}
        {...item}
      />
    ));
  }

  async function handleSortList(sortValue) {
    try {
      if (pageIndex !== 1) setPageIndex(1);
      if (!hasMore) setHasMore(true);
      const response = await api.getNewMusic(
        sortValue,
        DEFAULT_PAGE_ITEM_LIMIT,
        DEFAULT_PAGE_ITEM_START_INDEX,
      );
      setFeatured(response.data);
      setSortValue(sortValue);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleLoadMore() {
    try {
      const response = await api.getNewMusic(
        sortValue,
        12,
        DEFAULT_PAGE_ITEM_LIMIT + pageIndex * 12,
      );
      setFeatured(
        [...featured, ...response.data].filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
        ),
      );
      setPageIndex(pageIndex + 1);
      if (response.data.length < 12) {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getSlides() {
      try {
        const response = await api.getSlides();
        setSlides(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getSlides();
  }, []);

  useEffect(() => {
    async function getNewMusics() {
      try {
        const response = await api.getNewMusic(
          sortValue,
          DEFAULT_PAGE_ITEM_LIMIT,
          DEFAULT_PAGE_ITEM_START_INDEX,
        );
        setFeatured(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getNewMusics();
  }, []);

  useEffect(() => {
    document.title = `${t('FeaturedScreen.heading')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('FeaturedScreen.heading'),
      path: '',
    },
  ];

  const sorts = [
    {
      name: t('FeaturedScreen.filter_featured'),
      sortValue: 'Featured:desc',
      defaultChecked: true,
    },
    {
      name: t('FeaturedScreen.filter_popular'),
      sortValue: 'Popular:desc',
      defaultChecked: false,
    },
    {
      name: t('FeaturedScreen.filter_newest'),
      sortValue: 'createdAt:desc',
      defaultChecked: false,
    },
  ];

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
      </div>
      <br />
      <Sliders slides={slides} />
      <InfiniteScroll
        dataLength={featured.length}
        next={handleLoadMore}
        hasMore={hasMore}
        loader={
          <CircleLoading
            showLoading
            css={css`
              position: relative;
              height: 280px;
              background-color: transparent;
            `}
          />
        }
      >
        <FeaturedWrapper
          sorts={sorts}
          heading={t('FeaturedScreen.heading')}
          handleSortList={handleSortList}
        >
          <FeaturedList>{renderFeaturedItems()}</FeaturedList>
        </FeaturedWrapper>
      </InfiniteScroll>
    </Layout>
  );
}
