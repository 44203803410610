import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeMusicVideo, setPlaying } from '../actions';
import { api } from '../api';
import AlbumWrapper from '../components/HomePageComponents/AlbumWrapper';
import AlbumItem from '../components/HomePageComponents/AlbumWrapper/AlbumItem';
import AlbumList from '../components/HomePageComponents/AlbumWrapper/AlbumList';
import ArtistWrapper from '../components/HomePageComponents/ArtistWrapper';
import ArtistItem from '../components/HomePageComponents/ArtistWrapper/ArtistItem';
import ArtistList from '../components/HomePageComponents/ArtistWrapper/ArtistList';
import ChartMusicWrapper from '../components/HomePageComponents/ChartMusicWrapper';
import ChartMusicItem from '../components/HomePageComponents/ChartMusicWrapper/ChartMusicItem';
import ChartMusicList from '../components/HomePageComponents/ChartMusicWrapper/ChartMusicList';
import FeaturedWrapper from '../components/HomePageComponents/FeaturedWrapper';
import FeaturedItem from '../components/HomePageComponents/FeaturedWrapper/FeaturedItem';
import FeaturedList from '../components/HomePageComponents/FeaturedWrapper/FeaturedList';
import NewWrapper from '../components/HomePageComponents/NewWrapper';
import NewItem from '../components/HomePageComponents/NewWrapper/NewItem';
import NewList from '../components/HomePageComponents/NewWrapper/NewList';
import PlayingWrapper from '../components/HomePageComponents/PlayingWrapper';
import Slides from '../components/HomePageComponents/Slides';
import PlayingQueueItem from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueItem';
import PlayingList from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueList';
import Layout from '../components/Shared/Layout';
import {
  DEFAULT_NEW_RELEASE_SORT,
  DEFAULT_PAGE_ITEM_START_INDEX,
} from '../config';
import { AudioContext } from '../contexts/AudioContext';

export default function HomePage() {
  const { dispatch } = useContext(AudioContext);
  const [slides, setSlides] = useState([]);
  const [topCharts, setTopCharts] = useState([]);
  const [newMusics, setNewMusics] = useState([]);
  const [featuredMusics, setFeaturedMusics] = useState([]);
  const [artists, setArtists] = useState([]);
  const [albums, setAlbums] = useState([]);
  const playingQueueList = (
    JSON.parse(localStorage.getItem('playQueue')) || []
  ).reverse();

  const { t } = useTranslation();

  function handleChangeMusicVideo(musics, globalPlayIndex) {
    dispatch(
      changeMusicVideo(musics, globalPlayIndex === -1 ? 0 : globalPlayIndex),
    );
    dispatch(setPlaying(true));
  }

  function handlePlayList(musics, vid) {
    const globalPlayIndex = _.findIndex(musics, (item) => item.vid === vid);
    handleChangeMusicVideo(musics, globalPlayIndex);
  }

  function renderNewMusicItem() {
    return newMusics.map((item) => (
      <NewItem
        key={item.id}
        changeMusicVideo={handleChangeMusicVideo}
        {...item}
      />
    ));
  }

  function renderFeaturedMusics() {
    return featuredMusics.map((item) => (
      <FeaturedItem
        key={item.id}
        changeMusicVideo={handleChangeMusicVideo}
        {...item}
      />
    ));
  }

  function renderArtists() {
    return artists.map((item) => <ArtistItem key={item.id} {...item} />);
  }

  function renderAlbums() {
    return albums.map((item) => <AlbumItem key={item.id} {...item} />);
  }

  function renderPlayingQueueItem() {
    const musics = playingQueueList.slice(0, 5);
    return musics.map((item) => (
      <PlayingQueueItem
        isPlayQueue
        key={item.vid}
        {...item}
        handlePlayList={(vid) => handlePlayList(musics, vid)}
      />
    ));
  }

  function renderTopCharts() {
    const countries = Object.keys(topCharts);
    return Object.values(topCharts).map((item, index) => {
      return (
        <ChartMusicWrapper name={countries[index]} key={countries[index]}>
          <ChartMusicList>
            {item.map((item) => (
              <ChartMusicItem
                key={item.id}
                {...item}
                changeMusicVideo={handleChangeMusicVideo}
              />
            ))}
          </ChartMusicList>
        </ChartMusicWrapper>
      );
    });
  }

  useEffect(() => {
    document.title = `${t('HomeScreen.home_name')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  useEffect(() => {
    async function getNewMusics() {
      try {
        const response = await api.getNewMusic(
          DEFAULT_NEW_RELEASE_SORT,
          12,
          DEFAULT_PAGE_ITEM_START_INDEX,
        );
        setNewMusics(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getNewMusics();
  }, []);

  useEffect(() => {
    async function getFeaturedMusics() {
      try {
        const response = await api.getFeaturedMusics();
        setFeaturedMusics(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getFeaturedMusics();
  }, []);

  useEffect(() => {
    async function getSlides() {
      try {
        const response = await api.getSlides();
        setSlides(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getSlides();
  }, []);

  useEffect(() => {
    async function getArtists() {
      try {
        const response = await api.getArtists(6);
        setArtists(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getArtists();
  }, []);

  useEffect(() => {
    async function getTopChart() {
      try {
        const response = await api.getTopChart();
        setTopCharts(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getTopChart();
  }, []);

  useEffect(() => {
    async function getAlbums() {
      try {
        const response = await api.getAlbums(3);
        setAlbums(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getAlbums();
  }, []);

  return (
    <Layout>
      <Slides slides={slides} />
      <NewWrapper heading={t('HomeScreen.new_heading')}>
        <NewList>{renderNewMusicItem()}</NewList>
      </NewWrapper>
      <FeaturedWrapper>
        <FeaturedList>{renderFeaturedMusics()}</FeaturedList>
      </FeaturedWrapper>
      <ArtistWrapper>
        <ArtistList>{renderArtists()}</ArtistList>
      </ArtistWrapper>
      <section className="row row--grid">{renderTopCharts()}</section>
      <AlbumWrapper>
        <AlbumList>{renderAlbums()}</AlbumList>
      </AlbumWrapper>
      <PlayingWrapper total={playingQueueList.length}>
        <PlayingList>{renderPlayingQueueItem()}</PlayingList>
      </PlayingWrapper>
    </Layout>
  );
}
