/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { api } from '../api';
import GenreList from '../components/HomePageComponents/ArtistWrapper/ArtistList';
import GenresWrapper from '../components/HomePageComponents/NewWrapper';
import Sliders from '../components/HomePageComponents/Slides';
import GenreItem from '../components/MusicGenreComponent/MusicGenreItem';
import Breadcrumb from '../components/Shared/BreadCrumb';
import CircleLoading from '../components/Shared/CircleLoading';
import Layout from '../components/Shared/Layout';
import {
  DEFAULT_PAGE_ITEM_LIMIT,
  DEFAULT_PAGE_ITEM_START_INDEX,
} from '../config';

export default function MusicGenrePage() {
  const { t } = useTranslation();
  const [genres, setGenres] = useState([]);
  const [slides, setSlides] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  function renderGenres() {
    return genres.map((item) => <GenreItem key={item.id} {...item} />);
  }

  async function handleLoadMore() {
    try {
      const response = await api.getGenres(
        12,
        DEFAULT_PAGE_ITEM_LIMIT + pageIndex * 12,
      );
      setGenres(
        [...genres, ...response.data].filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
        ),
      );
      setPageIndex(pageIndex + 1);
      if (response.data.length < 12) {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getNewMusics() {
      try {
        const response = await api.getGenres(
          DEFAULT_PAGE_ITEM_LIMIT,
          DEFAULT_PAGE_ITEM_START_INDEX,
        );
        setGenres(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getNewMusics();
  }, []);

  useEffect(() => {
    async function getSlides() {
      try {
        const response = await api.getSlides();
        setSlides(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getSlides();
  }, []);

  useEffect(() => {
    document.title = `${t('GenresScreen.heading')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('GenresScreen.heading'),
      path: '',
    },
  ];

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
      </div>
      <br />
      <Sliders slides={slides} />
      <InfiniteScroll
        dataLength={genres.length}
        next={handleLoadMore}
        hasMore={hasMore}
        loader={
          <CircleLoading
            showLoading
            css={css`
              position: relative;
              height: 280px;
              background-color: transparent;
            `}
          />
        }
      >
        <GenresWrapper heading={t('GenresScreen.heading')} isDetailsListing>
          <GenreList>{renderGenres()}</GenreList>
        </GenresWrapper>
      </InfiniteScroll>
    </Layout>
  );
}
