import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeMusicVideo, setPlaying } from '../actions';
import { api } from '../api';
import FavoriteWrapper from '../components/PlayQueueComponents/PlayQueueWrapper';
import FavoriteItem from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueItem';
import FavoriteList from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueList';
import Layout from '../components/Shared/Layout';
import { BASE_URL } from '../config';
import { AudioContext } from '../contexts/AudioContext';
import { AuthContext } from '../contexts/AuthContext';

export default function FavoritesPage() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AudioContext);
  const [musics, setMusics] = useState([]);

  function handlePlayList(vid) {
    const globalPlayIndex = _.findIndex(musics, (item) => item.vid === vid);
    dispatch(
      changeMusicVideo(musics, globalPlayIndex === -1 ? 0 : globalPlayIndex),
    );
    dispatch(setPlaying(true));
  }

  function renderFavoriteItems() {
    return musics.map((item) => (
      <FavoriteItem key={item.vid} {...item} handlePlayList={handlePlayList} />
    ));
  }

  useEffect(() => {
    document.title = `${t('HomeScreen.my_favorites_name')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  useEffect(() => {
    async function handleGetFavorites() {
      try {
        const response = await api.getFavoritesMusic({
          uid: user.uid,
          limit: 100,
        });
        setMusics(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    if (user.uid) handleGetFavorites();
  }, [user.uid]);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('HomeScreen.my_favorites_name'),
      path: '',
    },
  ];

  return (
    <Layout>
      <FavoriteWrapper
        name="My favorites"
        thumbnail={`${BASE_URL}/music/get-music-thumb/${musics[0]?.vid}`}
        totalTracks={musics.length}
        breadcumbs={breadcumbs}
        handlePlayAll={handlePlayList}
      >
        <FavoriteList>{renderFavoriteItems()}</FavoriteList>
      </FavoriteWrapper>
    </Layout>
  );
}
