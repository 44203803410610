import React from 'react';
import { Link } from 'react-router-dom';
import { STRAPI_URI } from '../../../config';

export default function MusicGenreItem({ Genre, url, id }) {
  return (
    <div className="col-6 col-sm-4 col-lg-2">
      <Link className="artist" to={`/genres/${Genre}/${id}`}>
        <div className="artist__cover genre-item">
          <img
            className="genre-item-img"
            src={`${STRAPI_URI}${url}`}
            alt={Genre}
          />
        </div>
        <h3 className="artist__title">{Genre}</h3>
      </Link>
    </div>
  );
}
