import { BASE_URL } from '../config';

export function getBase64(str) {
  return Buffer.from(str).toString('base64');
}

export function getMp3Url(vid) {
  const src = `${BASE_URL}/audio/youtube-downloader?${getBase64(
    `url=https://www.youtube.com/watch?v=${vid}`,
  )}`;
  return src;
}

export function formatViewValue(val) {
  if (val >= 1000000000) {
    return (val / 1000000000).toFixed(1) + 'B';
  }
  if (val >= 1000000) {
    return (val / 1000000).toFixed(1) + 'M';
  }
  if (val > 1000) {
    return (val / 1000).toFixed(3);
  }
  return val;
}

export function durationCalc(duration) {
  try {
    let date = new Date(null);
    date.setSeconds(Math.floor(duration));
    return date.toISOString().substr(11, 8);
  } catch (error) {
    return '';
  }
}

export function customLongString(str, numberOfLength) {
  return str?.length > numberOfLength
    ? str.slice(0, numberOfLength) + '...'
    : str;
}

export function getQueryString(str) {
  const search = str.substring(1);
  if (!search) return {};
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}',
  );
}

export function mapMusicInfo(data) {
  if (!data?.length) return [];
  return data.map(({ Name, Vid, Channel, Duration }) => ({
    title: Name,
    vid: Vid,
    duration: Duration,
    channel: Channel,
  }));
}

export function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}
