import React from 'react';
import { Link } from 'react-router-dom';
import { STRAPI_URI } from '../../../../config';

export default function ArtistItem({ Name, url, id }) {
  return (
    <div className="col-6 col-sm-4 col-lg-2">
      <Link className="artist" to={`/artists/${Name}/${id}`}>
        <div className="artist__cover">
          <img src={`${STRAPI_URI}${url}`} alt={Name} />
        </div>
        <h3 className="artist__title">{Name}</h3>
      </Link>
    </div>
  );
}
