const BASE_URL = 'https://data.grabtube.cc';
// const BASE_URL = 'http://localhost:5001';
const YT_THUMB_URL = 'https://img.youtube.com/vi/';
const STRAPI_URI = 'https://mydb.grabtube.cc';

const ONE_SLIDE_CONFIGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const DEFAULT_PAGE_ITEM_LIMIT = 30;
const DEFAULT_PAGE_ITEM_START_INDEX = 0;
const DEFAULT_NEW_RELEASE_SORT = 'createdAt:desc';
const DEFAULT_FEATURED_SORT = 'Featured:desc';
const DEFAULT_TOP_CHART_SORT = 'Rank:asc';

const ITEMS_PER_PAGE_LIMIT = {
  albums: 8,
  artist: 30,
  featured: 30,
  newRelease: 30,
  search: 28,
  topChart: 30,
};

const FORM_SUBMITTING_STATUS = {
  none: 2,
  processing: 1,
  done: 0,
};

export {
  BASE_URL,
  STRAPI_URI,
  YT_THUMB_URL,
  ONE_SLIDE_CONFIGS,
  DEFAULT_PAGE_ITEM_LIMIT,
  DEFAULT_NEW_RELEASE_SORT,
  DEFAULT_FEATURED_SORT,
  DEFAULT_TOP_CHART_SORT,
  ITEMS_PER_PAGE_LIMIT,
  FORM_SUBMITTING_STATUS,
  DEFAULT_PAGE_ITEM_START_INDEX,
};
