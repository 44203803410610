/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

Breadcrumb.propTypes = {
  breadcumbs: PropTypes.array.isRequired,
};

export default function Breadcrumb({ breadcumbs }) {
  function renderBreadcrumb() {
    return breadcumbs.map((item, index) => {
      if (index !== breadcumbs.length - 1) {
        return (
          <li key={item.path} className="breadcrumb__item">
            <Link to={item.path}>{item.name}</Link>
          </li>
        );
      }
      return (
        <li
          key={item.path}
          className="breadcrumb__item breadcrumb__item--active"
        >
          {item.name}
        </li>
      );
    });
  }

  return (
    <ul
      css={css`
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        li {
          font-size: 14px;
          color: #c0c0c0;
          transition: 0.5s ease;
          position: relative;
          margin-right: 34px;
          &:not(:last-child):before {
            content: '';
            position: absolute;
            left: 100%;
            top: 1px;
            bottom: 0;
            width: 34px;
            background: url(${require('../../../assets/images/breadcrumb.svg')
                .default})
              no-repeat center/16px auto;
          }
          a {
            color: #c0c0c0;
            &:hover {
              color: #25a56a;
            }
          }
        }
      `}
      className="breadcrumb"
    >
      {renderBreadcrumb()}
    </ul>
  );
}
