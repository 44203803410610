import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AlbumDetailsPage from '../../pages/AlbumDetailsPage';
import AlbumListPage from '../../pages/AlbumListPage';
import ArtistDetailsPage from '../../pages/ArtistDetailsPage';
import ArtistPage from '../../pages/ArtistPage';
import ContactPage from '../../pages/ContactPage';
import FavoritesPage from '../../pages/FavoritesPage';
import FeaturedPage from '../../pages/FeaturedPage';
import HomePage from '../../pages/HomePage';
import LoginPage from '../../pages/LoginPage';
import MusicDetailsPage from '../../pages/MusicDetails';
import MusicGenreDetailsPage from '../../pages/MusicGenreDetailsPage';
import MusicGenrePage from '../../pages/MusicGenrePage';
import MyMusicPage from '../../pages/MyMusicPage';
import NewReleasePage from '../../pages/NewReleasePage';
import PlaylistDetailsPage from '../../pages/PlaylistDetailsPage';
import PlayQueuePage from '../../pages/PlayQueuePage';
import ProfilePage from '../../pages/Profile';
import RecentPlayedPage from '../../pages/RecentPlayed';
import SearchPage from '../../pages/SearchPage';
import TopChartPage from '../../pages/TopChartPage';
import TrendsPage from '../../pages/TrendsPage';
import ScrollToTop from '../Shared/ScrollToTop';
import AudioPlayer from '../Shared/AudioPlayer';

function App() {
  return (
    <>
      <AudioPlayer />
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/trends">
          <TrendsPage />
        </Route>
        <Route exact path="/genres">
          <MusicGenrePage />
        </Route>
        <Route exact path="/genres/:name/:id">
          <MusicGenreDetailsPage />
        </Route>
        <Route path="/albums/:albumId" exact>
          <AlbumDetailsPage />
        </Route>
        <Route path="/music" exact>
          <MusicDetailsPage />
        </Route>
        <Route path="/new-release" exact>
          <NewReleasePage />
        </Route>
        <Route path="/featured" exact>
          <FeaturedPage />
        </Route>
        <Route path="/top-chart/:country" exact>
          <TopChartPage />
        </Route>
        <Route path="/albums-list" exact>
          <AlbumListPage />
        </Route>
        <Route path="/artists" exact>
          <ArtistPage />
        </Route>
        <Route path="/artists/:name/:id" exact>
          <ArtistDetailsPage />
        </Route>
        <Route path="/playing-queue" exact>
          <PlayQueuePage />
        </Route>
        <Route path="/recent-played" exact>
          <RecentPlayedPage />
        </Route>
        <Route path="/favorites" exact>
          <FavoritesPage />
        </Route>
        <Route path="/my-music" exact>
          <MyMusicPage />
        </Route>
        <Route path="/playlists" exact>
          <PlaylistDetailsPage />
        </Route>
        <Route path="/search" exact>
          <SearchPage />
        </Route>
        <Route path="/profile" exact>
          <ProfilePage />
        </Route>
        <Route path="/contact" exact>
          <ContactPage />
        </Route>
        <Route path="/signin" exact>
          <LoginPage />
        </Route>
      </Switch>
    </>
  );
}

export default App;
