import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
import LoginBox from './LoginBox';
import Menu from './Menu';
import SearchBox from './SearchBox';

export default function NavBar({ isShownSidebar, setSideBar }) {
  const [isShowSearch, setShowSearch] = useState(false);

  function setSearch() {
    setShowSearch(!isShowSearch);
  }
  return (
    <header className="header">
      <div className="header__content">
        <div className="header__logo">
          <Link to="/">
            <img
              src={require('../../../assets/images/logo.png').default}
              alt=""
            />
          </Link>
        </div>
        <Menu />
        <SearchBox isShowSearch={isShowSearch} setSearch={setSearch} />
        <div className="header__actions">
          <div
            onClick={setSearch}
            className="header__action header__action--search"
          >
            <button className="header__action-btn" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
              </svg>
            </button>
          </div>
          <LanguageSwitcher />
          <LoginBox />
        </div>
        <button
          onClick={setSideBar}
          className={`header__btn${
            isShownSidebar ? ' header__btn--active' : ''
          }`}
          type="button"
        >
          <span />
          <span />
          <span />
        </button>
      </div>
    </header>
  );
}
