export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const CHANGE_MUSIC = 'CHANGE_MUSIC';
export const SET_PLAYING = 'SET_PLAYING';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
