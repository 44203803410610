import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyMusicWrapper from '../components/ProfileComponents/MyMusicWrapper';
import PlayingWrapper from '../components/ProfileComponents/PlayingWrapper';
import ProfileWrapper from '../components/ProfileComponents/ProfileWrapper';
import Breadcrumb from '../components/Shared/BreadCrumb';
import Layout from '../components/Shared/Layout';

export default function ProfilePage() {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(1);
  const breadcumbs = [
    {
      name: 'Trang chủ',
      path: '/',
    },
    {
      name: 'Profile',
      path: '',
    },
  ];

  const tabNames = ['Profile', 'Playing', 'My music'];

  function renderTabContent() {
    switch (tabIndex) {
      case 1:
        return <ProfileWrapper />;
      case 2:
        return <PlayingWrapper />;
      case 3:
        return <MyMusicWrapper />;
      default:
        return null;
    }
  }

  function renderTabNames() {
    return _.map(tabNames, (item, index) => (
      <li
        onClick={() => setTabIndex(index + 1)}
        key={item}
        className="nav-item"
      >
        <span className={`nav-link${tabIndex === index + 1 ? ' active' : ''}`}>
          {item}
        </span>
      </li>
    ));
  }

  useEffect(() => {
    document.title = `${t('HomeScreen.profile_name')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>Profile</h1>
          </div>
        </div>
      </div>
      <div className="row row--grid">
        <div className="col-12">
          <div className="profile">
            <ul
              className="nav nav-tabs profile__tabs"
              id="profile__tabs"
              role="tablist"
            >
              {renderTabNames()}
            </ul>
          </div>
          {renderTabContent()}
        </div>
      </div>
    </Layout>
  );
}
