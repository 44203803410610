/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';

export default function FooterTab() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const sidebarContent = [
    {
      name: t('HomeScreen.sidebar_home'),
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M20,8h0L14,2.74a3,3,0,0,0-4,0L4,8a3,3,0,0,0-1,2.26V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V10.25A3,3,0,0,0,20,8ZM14,20H10V15a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Zm5-1a1,1,0,0,1-1,1H16V15a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v5H6a1,1,0,0,1-1-1V10.25a1,1,0,0,1,.34-.75l6-5.25a1,1,0,0,1,1.32,0l6,5.25a1,1,0,0,1,.34.75Z" />
        </svg>
      ),
      path: '/',
    },
    {
      name: t('HomeScreen.sidebar_top_chart'),
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12,6a1,1,0,0,0-1,1V17a1,1,0,0,0,2,0V7A1,1,0,0,0,12,6ZM7,12a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V13A1,1,0,0,0,7,12Zm10-2a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V11A1,1,0,0,0,17,10Zm2-8H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z" />
        </svg>
      ),
      path: '/top-chart/global',
    },
    {
      name: t('HomeScreen.sidebar_trend'),
      icon: (
        <svg
          width={20}
          height={20}
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17 16.25C17 16.6642 16.6642 17 16.25 17L4.75 17C3.7835 17 3 16.2165 3 15.25V3.75C3 3.33579 3.33579 3 3.75 3C4.16421 3 4.5 3.33579 4.5 3.75L4.5 15.25C4.5 15.3881 4.61193 15.5 4.75 15.5L16.25 15.5C16.6642 15.5 17 15.8358 17 16.25ZM12 5.75C12 5.33579 12.3358 5 12.75 5H16.25C16.6642 5 17 5.33579 17 5.75V9.25C17 9.66421 16.6642 10 16.25 10C15.8358 10 15.5 9.66421 15.5 9.25V7.56063L11.5303 11.5303C11.3897 11.671 11.1989 11.75 11 11.75C10.8011 11.75 10.6103 11.671 10.4696 11.5303L9 10.0607L6.78033 12.2803C6.48744 12.5732 6.01256 12.5732 5.71967 12.2803C5.42678 11.9874 5.42678 11.5126 5.71967 11.2197L8.46967 8.46967C8.61032 8.32902 8.80109 8.25 9 8.25C9.19892 8.25 9.38968 8.32902 9.53033 8.46967L11 9.93934L14.4393 6.5H12.75C12.3358 6.5 12 6.16421 12 5.75Z" />
        </svg>
      ),
      path: '/trends',
    },
    {
      name: t('HomeScreen.sidebar_my_music'),
      icon: (
        <svg
          css={css`
            width: 20px !important;
            height: 20px !important;
          `}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="mdi-account-music"
          viewBox="0 0 20 20"
        >
          <path d="M11,14C12,14 13.05,14.16 14.2,14.44C13.39,15.31 13,16.33 13,17.5C13,18.39 13.25,19.23 13.78,20H3V18C3,16.81 3.91,15.85 5.74,15.12C7.57,14.38 9.33,14 11,14M11,12C9.92,12 9,11.61 8.18,10.83C7.38,10.05 7,9.11 7,8C7,6.92 7.38,6 8.18,5.18C9,4.38 9.92,4 11,4C12.11,4 13.05,4.38 13.83,5.18C14.61,6 15,6.92 15,8C15,9.11 14.61,10.05 13.83,10.83C13.05,11.61 12.11,12 11,12M18.5,10H20L22,10V12H20V17.5A2.5,2.5 0 0,1 17.5,20A2.5,2.5 0 0,1 15,17.5A2.5,2.5 0 0,1 17.5,15C17.86,15 18.19,15.07 18.5,15.21V10Z" />
        </svg>
      ),
      path: '/my-music',
    },
  ];

  function renderSidebarContent() {
    return _.map(sidebarContent, ({ name, icon, path }) => (
      <li key={name} className="sidebar__nav-item__bottom">
        <Link
          to={path}
          className={`sidebar__nav-link__mobile${
            pathname === path ? ' sidebar__nav-link--active' : ''
          }`}
        >
          {icon}
          <div>
            <span className="title_bottom_nav">{name}</span>
          </div>
        </Link>
      </li>
    ));
  }
  return (
    <div className="navbar-footer">
      <ul className="sidebar__nav__mobile">{renderSidebarContent()}</ul>
    </div>
  );
}
