/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ONE_SLIDE_CONFIGS } from '../../../config';
import CarouselProvider from '../../Shared/CarouselProvider';
import SlideItem from './SlideItem';

const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className="main__nav main__nav--hero main__nav--next"
    data-nav="#hero"
    type="button"
    css={css`
      position: absolute;
      left: auto;
      width: 30px;
      top: auto;
      bottom: 25px;
      right: 30px;
      z-index: 100;
      &:before {
        display: none;
      }
      svg {
        width: 30px;
        height: auto;
        fill: #fff;
        transition: fill 0.5s ease;
      }
    `}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
    </svg>
  </button>
);

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className="main__nav main__nav--hero main__nav--prev"
    data-nav="#hero"
    type="button"
    css={css`
      position: absolute;
      top: auto;
      width: 30px;
      bottom: 25px;
      right: 70px;
      z-index: 100;
      &:before {
        display: none;
      }
      svg {
        width: 30px;
        height: auto;
        fill: #fff;
        transition: fill 0.5s ease;
      }
    `}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" />
    </svg>
  </button>
);

export default function Sliders({ slides }) {
  function renderSlides() {
    return slides.map((item) => <SlideItem key={item.id} {...item} />);
  }

  return (
    <section>
      <CarouselProvider
        settings={{
          ...ONE_SLIDE_CONFIGS,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        }}
      >
        {renderSlides()}
      </CarouselProvider>
    </section>
  );
}
