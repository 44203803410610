/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  auth,
  signinWithGoogle,
  signWithFacebook,
} from '../../../services/firebaseConfig';
import CircleLoading from '../../Shared/CircleLoading';

function LoginComponent() {
  const { t } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const history = useHistory();

  async function handleLoginWithFacebook() {
    await signWithFacebook();
  }

  async function handleLoginWithGoogle() {
    await signinWithGoogle();
  }

  useEffect(() => {
    function handleCheckUserAuth() {
      setShowLoading(true);
      auth()
        .getRedirectResult()
        .then((result) => {
          if (!result.user) {
            setShowLoading(false);
          }
        });
    }
    handleCheckUserAuth();
  }, [history]);

  return showLoading ? (
    <CircleLoading showLoading />
  ) : (
    <section
      css={css`
        height: 650px;
        margin-top: 100px;
        @media only screen and (max-width: 768px) {
          height: 300px;
        }
      `}
      className="login-page"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-8 mx-auto text-center">
            <article
              css={css`
                .social-btn {
                  display: block;
                  outline: 0;
                  box-shadow: none;
                  margin-bottom: 1.5rem;
                  width: 100%;
                  color: #fff;
                  line-height: 3.5rem;
                  border-radius: 0.25rem;
                  font-weight: 400;
                  box-sizing: border-box;
                  padding-right: 1rem;
                }

                p {
                  position: relative;
                  cursor: pointer;
                }

                .social-btn i {
                  display: inline-block;
                  width: 5rem;
                  padding-right: 1.5rem;
                  font-size: 1.6rem;
                  vertical-align: middle;
                  text-align: center;
                }

                .fb-btn {
                  background-color: #3b5998;
                  transition: 0.1s background ease-in-out;
                }

                .gp-btn {
                  background-color: #d34836;
                  transition: 0.1s background ease-in-out;
                }

                .fb-btn:hover {
                  background-color: #3f60a5;
                }

                .gp-btn:hover {
                  background-color: #db4835;
                }

                .divider {
                  content: '';
                  display: block;
                  height: 100%;
                  box-sizing: border-box;
                  position: absolute;
                  top: 0;
                  left: 4.5rem;
                }

                .divider-fb {
                  box-shadow: 1px 0 0 0.03em #496bae, 3px 0 0 0.03em #2f529b;
                }

                .divider-gp {
                  box-shadow: 0.15em 0 0 0.03em #d36f62,
                    0.25em 0 0 0.03em #cb321f;
                }

                ::-webkit-input-placeholder {
                  color: #3b5998;
                }

                :-moz-placeholder {
                  color: #3b5998;
                }

                ::-moz-placeholder {
                  color: #3b5998;
                }

                :-ms-input-placeholder {
                  color: #3b5998;
                }

                @media only screen and (max-width: 768px) {
                  html {
                    font-size: 13px;
                  }

                  #form-container h1 {
                    font-size: 1.3rem;
                  }

                  main {
                    width: 70%;
                    min-width: 300px;
                  }
                }

                @keyframes animate {
                  0% {
                    text-decoration: none;
                  }

                  20% {
                    text-decoration: underline;
                    -moz-text-decoration-color: #00c1a8;
                    text-decoration-color: #00c1a8;
                  }

                  40% {
                    text-decoration: none;
                  }

                  60% {
                    text-decoration: underline;
                    -moz-text-decoration-color: #00c1a8;
                    text-decoration-color: #00c1a8;
                  }

                  80% {
                    color: #00c1a8;
                  }
                }
              `}
              id="form-container"
            >
              <div className="sign__content">
                <form action="#" className="sign__form">
                  <a href="index.html" className="sign__logo">
                    <img
                      src={require('../../../assets/images/logo.png').default}
                      alt=""
                    />
                  </a>
                  <div>
                    <p onClick={handleLoginWithFacebook}>
                      <div className="social-btn fb-btn">
                        <span className="divider divider-fb" />
                        <svg
                          css={css`
                            fill: #fff;
                            width: 5rem;
                            height: 20px;
                            vertical-align: middle;
                          `}
                          id="Bold"
                          enableBackground="new 0 0 24 24"
                          height="512"
                          viewBox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" />
                        </svg>
                        {t('LoginScreen.with_facebook')}
                      </div>
                    </p>
                    <p onClick={handleLoginWithGoogle}>
                      <div className="social-btn gp-btn">
                        <span className="divider divider-gp" />
                        <svg
                          css={css`
                            fill: #fff;
                            width: 5rem;
                            height: 20px;
                            vertical-align: middle;
                          `}
                          id="Bold"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ionicon"
                          viewBox="0 0 512 512"
                        >
                          <path d="M473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 01-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4 57.73 0 112.45-22.62 151.45-63.66 38.34-40.4 58.17-96.3 58.17-154.9 0-24.67-2.48-39.32-2.59-39.96z" />
                        </svg>
                        {t('LoginScreen.with_google')}
                      </div>
                    </p>
                  </div>
                </form>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginComponent;
