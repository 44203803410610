import React from 'react';
import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './assets/css/audio-override.css';
import './assets/css/bootstrap-grid.min.css';
import './assets/css/bootstrap-reboot.min.css';
import './assets/css/checkbox.css';
import './assets/css/fonts-awesome.min.css';
import './assets/css/loading.css';
import './assets/css/player.css';
import './assets/css/popup.css';
import './assets/css/animations.css';
import './assets/css/styles.css';
import App from './components/App';
import Provider from './contexts';
import i18n from './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: 'fade',
  containerStyle: {
    zIndex: 10000,
  },
};

ReactDOM.render(
  <Router>
    <I18nextProvider i18n={i18n}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Provider>
          <App />
        </Provider>
      </AlertProvider>
    </I18nextProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
