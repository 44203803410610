/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { BASE_URL } from '../../../config';
import { formatViewValue, durationCalc } from '../../../utils';
import VideoActions from './VideoAction';

function removeFromQueue(vid) {
  const storedData = JSON.parse(localStorage.getItem('playQueue')) || [];
  const index = _.findIndex(storedData, (item) => item.vid === vid);
  if (!storedData.length || index === -1) return;
  const newData = _.filter(storedData, (item) => item.vid !== vid);
  localStorage.setItem('playQueue', JSON.stringify(newData));
}

function VideoInfos({
  videoId,
  views,
  created,
  title,
  t,
  duration,
  author,
  changeMusicVideo,
}) {
  function handleChangeMusicVideo() {
    removeFromQueue(videoId);
    changeMusicVideo([
      {
        vid: videoId,
        title,
        channel: author.name,
      },
    ]);
  }

  return (
    <div className="col-xl-8 col-lg-7">
      {videoId ? (
        <div className="music-infos">
          <div
            onClick={handleChangeMusicVideo}
            css={css`
              position: relative;
              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                transition: 0.2s;
                cursor: pointer;
              }
            `}
            className="box-thumbnail"
          >
            <img
              css={css`
                width: 100%;
                max-width: 100%;
                border-radius: 4px;
                cursor: pointer;
              `}
              src={`${BASE_URL}/music/get-music-thumb/${videoId}`}
              alt={title}
            />
            <span
              css={css`
                position: absolute;
                bottom: 16px;
                right: 16px;
                padding: 2px 6px;
                color: rgb(255, 255, 255);
                background-color: rgba(0, 0, 0, 0.5);
                font-size: 13px;
                border-radius: 2px;
              `}
            >
              {durationCalc(duration / 1000)}
            </span>
            {videoId ? (
              <svg
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  width: 80px;
                  height: 80px;
                  cursor: pointer;
                  transform: translate(-50%, -50%);
                  @media only screen and (max-width: 768px) {
                    width: 40px;
                    height: 40px;
                  }
                `}
                xmlns="http://www.w3.org/2000/svg"
                width={26}
                height={26}
                focusable="false"
                viewBox="0 0 26 26"
              >
                <circle
                  cx={13}
                  cy={13}
                  r={11}
                  fill="none"
                  stroke="#f2f2f2"
                  strokeWidth={2}
                />
                <path fill="#f2f2f2" d="M17 13l-6 4V9" />
              </svg>
            ) : null}
          </div>
          <h3
            css={css`
              margin: 14px 0 6px 0;
              line-height: 24px;
              color: #ffffff;
              font-size: 16px;
              font-weight: 500;
            `}
            title={title}
          >
            {title}
          </h3>
          <div className="channel_info">
            <img
              css={css`
                max-width: 100%;
                border-radius: 50%;
                cursor: pointer;
                width: 50px;
                height: 50px;
              `}
              src={author.thumbnail}
              alt="Channel"
            />
            <h3
              css={css`
                margin: 14px 0 6px 16px;
                line-height: 24px;
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
              `}
              title={author.name}
            >
              {author.name}
            </h3>
          </div>
          <div
            css={css`
              span {
                color: #c0c0c0;
                font-size: 14px;
              }
            `}
          >
            <span>
              {formatViewValue(views)} {t('DetailsMvScreen.views')}
            </span>
            <span
              css={css`
                margin: 0 6px;
              `}
            >
              •
            </span>
            <span>{moment(created).format('ll')}</span>
          </div>
          <VideoActions
            vid={videoId}
            channel={author.name}
            title={title}
            duration={duration}
          />
        </div>
      ) : null}
    </div>
  );
}

export default React.memo(VideoInfos);
