/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { durationCalc, formatViewValue } from '../../../../utils';

export default function VideoItem({ vid, channel, title, duration, views, t }) {
  return (
    <div
      css={css`
        display: flex;
        padding-right: 6px;
        margin-bottom: 6px;
      `}
    >
      <div
        css={css`
          max-width: 45%;
          flex: 1 1 45%;
          margin-right: 12px;
          display: block;
        `}
      >
        <Link to={`/music?vid=${vid}`}>
          <div
            css={css`
              position: relative;
            `}
            className="box-image"
          >
            <img
              css={css`
                width: 100%;
                transition: 0.2s;
                border-radius: 4px;

                &:hover {
                  opacity: 0.8;
                }

                @media only screen and (max-width: 460px) {
                  height: 85px;
                  object-fit: cover;
                }
              `}
              title="Orange - 'Em Hát Ai Nghe' Official M/V"
              src={`https://i.ytimg.com/vi/${vid}/mqdefault.jpg`}
              alt="Orange - 'Em Hát Ai Nghe' Official M/V"
            />
            <span
              css={css`
                position: absolute;
                bottom: 8px;
                right: 8px;
                padding: 2px 6px;
                color: rgb(255, 255, 255);
                background-color: rgba(0, 0, 0, 0.5);
                font-size: 13px;
                border-radius: 2px;
              `}
            >
              {durationCalc(duration / 1000)}
            </span>
          </div>
        </Link>
      </div>
      <div
        css={css`
          max-width: 55%;
          flex: 1 1 55%;
        `}
      >
        <Link to={`/music?vid=${vid}`}>
          <h5
            css={css`
              font-size: 14px;
              color: #ffffff;
              line-height: 20px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              transition: 0.2s;
              margin-bottom: 4px;

              &:hover {
                color: #25a56a;
              }

              @media only screen and (max-width: 1200px) {
                font-size: 14px;
                line-height: 18px;
              }
            `}
          >
            {title}
          </h5>
        </Link>
        <div>
          <span
            css={css`
              color: #c0c0c0;
              font-size: 14px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            `}
          >
            {channel}
          </span>
          <div
            css={css`
              color: #c0c0c0;
              font-size: 14px;
            `}
            className="music-stast"
          >
            <span>
              {formatViewValue(views)} {t('DetailsMvScreen.views')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
