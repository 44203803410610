import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';
import * as types from '../actions/types';

export const LoadingContext = createContext();

const initialState = false;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return !state;
    case types.HIDE_LOADING:
      return !state;
    default:
      throw new Error('Unexpected actions');
  }
};

LoadingProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

function LoadingProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LoadingContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export default LoadingProvider;
