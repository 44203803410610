import * as types from './types';

export const changeMusicVideo = (musics, globalPlayIndex = 0) => {
  sessionStorage.setItem('musics', JSON.stringify(musics));
  return {
    type: types.CHANGE_MUSIC,
    payload: { musics, globalPlayIndex },
  };
};

export const showModal = (component) => ({
  type: types.SHOW_MODAL,
  payload: component,
});

export const setPlaying = (status) => ({
  type: types.SET_PLAYING,
  payload: status,
});

export const userAuth = (userInfo) => ({
  type: types.AUTH_USER_SUCCESS,
  payload: userInfo,
});

export const hideModal = () => ({ type: types.HIDE_MODAL });

// Loading handle
export function showLoading() {
  return { type: types.SHOW_LOADING };
}

export function hideLoading() {
  return { type: types.HIDE_LOADING };
}
