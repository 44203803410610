import PropTypes from 'prop-types';
import React from 'react';
import AudioProvider from './AudioContext';
import AuthProvider from './AuthContext';
import ModalProvider from './ModalContext';
import LoadingProvider from './LoadingContext';

Provider.propTypes = {
  children: PropTypes.object.isRequired,
};

function Provider({ children }) {
  return (
    <AudioProvider>
      <AuthProvider>
        <ModalProvider>
          <LoadingProvider>{children}</LoadingProvider>
        </ModalProvider>
      </AuthProvider>
    </AudioProvider>
  );
}

export default Provider;
