import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { changeMusicVideo, setPlaying } from '../actions';
import { api } from '../api';
import NewWrapper from '../components/HomePageComponents/NewWrapper';
import NewItem from '../components/HomePageComponents/NewWrapper/NewItem';
import NewList from '../components/HomePageComponents/NewWrapper/NewList';
import AlbumWrapper from '../components/PlayQueueComponents/PlayQueueWrapper';
import AlbumItem from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueItem';
import AlbumList from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueList';
import Layout from '../components/Shared/Layout';
import { DEFAULT_NEW_RELEASE_SORT, STRAPI_URI } from '../config';
import { AudioContext } from '../contexts/AudioContext';
import { mapMusicInfo } from '../utils';

export default function AlbumDetailsPage() {
  const { t } = useTranslation();
  const { dispatch } = useContext(AudioContext);
  const { albumId } = useParams();
  const [albumsInfo, setAlbumsInfos] = useState([]);
  const { videos, Name, url } = albumsInfo;
  const [newMusics, setNewMusics] = useState([]);

  function handleChangeMusicVideo(musics, globalPlayIndex) {
    dispatch(
      changeMusicVideo(musics, globalPlayIndex === -1 ? 0 : globalPlayIndex),
    );
    dispatch(setPlaying(true));
  }

  function handlePlayList(vid) {
    const musics = mapMusicInfo(videos);
    const globalPlayIndex = _.findIndex(musics, (item) => item.vid === vid);
    handleChangeMusicVideo(musics, globalPlayIndex);
  }

  function renderAlbums() {
    return videos?.map(
      ({
        id,
        Channel: channel,
        Name: title,
        Views: views,
        Vid: vid,
        Duration: duration,
      }) => (
        <AlbumItem
          key={id}
          handlePlayList={handlePlayList}
          {...{ channel, title, views, vid, duration }}
        />
      ),
    );
  }

  function renderNewMusicItem() {
    return newMusics.map((item) => (
      <NewItem
        key={item.id}
        changeMusicVideo={handleChangeMusicVideo}
        {...item}
      />
    ));
  }

  useEffect(() => {
    async function getAlbums() {
      const response = await api.getDetailsAlbum(albumId);
      setAlbumsInfos(response.data);
    }
    getAlbums();
  }, [albumId]);

  useEffect(() => {
    document.title = `${Name || ''} - ${t('HomeScreen.app_name')}`;
  }, [Name]);

  useEffect(() => {
    async function getNewMusics() {
      const response = await api.getNewMusic(12, DEFAULT_NEW_RELEASE_SORT);
      setNewMusics(response.data);
    }
    getNewMusics();
  }, []);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: Name,
      path: '',
    },
  ];

  return (
    <Layout>
      <AlbumWrapper
        thumbnail={`${STRAPI_URI}${url}`}
        name={Name}
        totalTracks={videos?.length}
        breadcumbs={breadcumbs}
        handlePlayAll={handlePlayList}
      >
        <AlbumList>{renderAlbums()}</AlbumList>
      </AlbumWrapper>
      <NewWrapper>
        <NewList>{renderNewMusicItem()}</NewList>
      </NewWrapper>
    </Layout>
  );
}
