/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { api } from '../../../../api';
import { getQueryString } from '../../../../utils';
import SearchSuggestions from './SearchSuggestions';

export default function SearchBox({ isShowSearch, setSearch }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { keyword } = getQueryString(history.location.search);
  const [searchValue, setSearchValue] = useState(keyword || '');
  const [searchSuggested, setSearchSuggested] = useState([]);
  const [isOpenSearchBox, setIsOpenSearch] = useState(true);

  async function handleChange({ target: { value } }) {
    if (!isOpenSearchBox) setIsOpenSearch(true);
    setSearchValue(value);
    try {
      const { data } = await api.getSuggestionsSearch(value);
      setSearchSuggested(data);
    } catch (error) {
      console.log(error);
    }
  }

  function handleCloseSearchSuggestion(value) {
    if (value) {
      setSearchValue(value);
    }
    setIsOpenSearch(false);
  }

  function handleSubmitForm(event) {
    handleCloseSearchSuggestion();
    event.preventDefault();
    history.push(`/search?keyword=${searchValue}`);
  }

  function handleClickSearch(event) {
    if (searchValue) handleSubmitForm(event);
  }

  useEffect(() => {
    if (history.location.pathname !== '/search') {
      setSearchValue('');
    }
  }, [history.location.pathname]);

  return (
    <form
      onSubmit={handleSubmitForm}
      autoComplete="off"
      acceptCharset="UTF-8"
      className={`header__search${
        isShowSearch ? ' header__search--active' : ''
      }`}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
        `}
        className="search-suggestion"
      >
        <input
          onChange={handleChange}
          type="text"
          name="query"
          id="query"
          value={searchValue}
          autoComplete="off"
          placeholder={t('SearchForm.placeholder')}
        />
        {isOpenSearchBox ? (
          <SearchSuggestions
            searchSuggested={searchSuggested}
            handleCloseSearchSuggestion={handleCloseSearchSuggestion}
          />
        ) : null}
      </div>
      <button onClick={handleClickSearch} type="button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
        </svg>
      </button>
      <button onClick={setSearch} type="button" className="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>
    </form>
  );
}
