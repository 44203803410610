import React, { useContext, useEffect, useState } from 'react';
import { changeMusicVideo, setPlaying } from '../../../actions';
import { api } from '../../../api';
import { AudioContext } from '../../../contexts/AudioContext';
import { AuthContext } from '../../../contexts/AuthContext';
import MyMusicList from '../PlayingWrapper/PlayingQueue';
import MyMusicItem from '../PlayingWrapper/PlayingQueue/PlayingQueueItem';
import PLaylistItem from './PlaylistItem';

export default function MyMusicWrapper() {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AudioContext);
  const [musics, setMusics] = useState([]);
  const [playlists, setPlaylists] = useState([]);

  function handlePlayList(vid) {
    const globalPlayIndex = _.findIndex(musics, (item) => item.vid === vid);
    dispatch(
      changeMusicVideo(musics, globalPlayIndex === -1 ? 0 : globalPlayIndex),
    );
    dispatch(setPlaying(true));
  }

  function renderMyMusicItems() {
    return musics.map((item) => (
      <MyMusicItem
        key={item.vid}
        {...item}
        handlePlayList={() => handlePlayList(item.vid)}
      />
    ));
  }

  function saveDeletedPlaylist(id) {
    const newData = playlists.filter((item) => item.id !== id);
    setPlaylists([...newData]);
  }

  function renderPlaylist() {
    return _.map(playlists, (item) => (
      <PLaylistItem
        key={item.id}
        {...item}
        saveDeletedPlaylist={saveDeletedPlaylist}
      />
    ));
  }

  useEffect(() => {
    async function handleGetFavorites() {
      try {
        const response = await api.getFavoritesMusic({
          uid: user.uid,
          limit: 5,
        });
        setMusics(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    if (user.uid) handleGetFavorites();
  }, [user.uid]);

  useEffect(() => {
    async function getPLaylistRequest() {
      try {
        const response = await api.getPlayList(user.uid);
        setPlaylists(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getPLaylistRequest();
  }, [user.uid]);

  return (
    <div className="tab-content">
      <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
        <div className="row row--grid">
          <MyMusicList name="My favorites" path="/favorites">
            {renderMyMusicItems()}
          </MyMusicList>
          <MyMusicList name="My playlist">{renderPlaylist()}</MyMusicList>
        </div>
      </div>
    </div>
  );
}
