import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MyMusicWrapper from '../components/ProfileComponents/MyMusicWrapper';
import Breadcrumb from '../components/Shared/BreadCrumb';
import Layout from '../components/Shared/Layout';

export default function MyMusicPage() {
  const { t } = useTranslation();
  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('HomeScreen.my_music_name'),
      path: '',
    },
  ];

  useEffect(() => {
    document.title = `${t('HomeScreen.my_music_name')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>{t('HomeScreen.my_music_name')}</h1>
          </div>
        </div>
      </div>
      <div className="row row--grid">
        <div className="col-12">
          <MyMusicWrapper />
        </div>
      </div>
    </Layout>
  );
}
