/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../Shared/BreadCrumb';

export default function PlayQueueWrapper({
  name,
  thumbnail,
  totalTracks,
  children,
  breadcumbs,
  handlePlayAll,
}) {
  const { t } = useTranslation();
  return (
    <div className="row row--grid">
      <div className="col-12">
        <Breadcrumb breadcumbs={breadcumbs} />
      </div>
      <div className="col-12">
        <div className="main__title main__title--page">
          <h1>{name}</h1>
        </div>
      </div>
      <div className="col-12">
        {totalTracks ? (
          <div className="release">
            <div className="release__content">
              <div className="release__cover">
                <img
                  css={css`
                    height: 258px;
                    object-fit: cover;
                  `}
                  src={thumbnail}
                  alt=""
                />
              </div>
              <div className="release__stat">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z" />
                  </svg>
                  {totalTracks} {t('PlayQueueDetailsScreen.tracks')}
                </span>
              </div>
              <span onClick={handlePlayAll} className="release__buy open-modal">
                {t('PlayQueueDetailsScreen.play_all')}
              </span>
            </div>
            <div className="release__list" data-scrollbar="true" tabIndex={-1}>
              <div
                css={css`
                  @media only screen and (max-width: 600px) {
                    ul {
                      height: auto;
                      overflow-y: visible;
                    }
                  }
                `}
                className="scroll-content"
              >
                {children}
              </div>
            </div>
          </div>
        ) : (
          <span
            css={css`
              color: #b1b1b1;
              font-size: 18px;
              margin-top: 10px;
              display: block;
            `}
          >
            {t('PlayQueueDetailsScreen.no_track')}
          </span>
        )}
      </div>
    </div>
  );
}
