import firebase from 'firebase';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

export const { auth } = firebase;
export const firestore = firebase.firestore();
export const db = firebase.firestore();
export const storage = firebase.storage();

const facebookProvider = new auth.FacebookAuthProvider();
const googleProvider = new auth.GoogleAuthProvider();

export async function signWithFacebook() {
  // eslint-disable-next-line no-return-await
  return await auth().signInWithRedirect(facebookProvider);
}

export async function signinWithGoogle() {
  // eslint-disable-next-line no-return-await
  return await auth().signInWithRedirect(googleProvider);
}

export const generateUserDocument = async (user) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    try {
      await userRef.set({
        ...user,
        created: Date.now(),
      });
      return user;
    } catch (error) {
      console.error('Error creating user document', error);
    }
  }
  return snapshot.data();
};
