import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeMusicVideo, setPlaying } from '../actions';
import RecentPlayedWrapper from '../components/PlayQueueComponents/PlayQueueWrapper';
import RecentPlayedItem from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueItem';
import RecentPlayList from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueList';
import Layout from '../components/Shared/Layout';
import { BASE_URL } from '../config';
import { AudioContext } from '../contexts/AudioContext';

export default function RecentPlayedPage() {
  const { t } = useTranslation();
  const { dispatch } = useContext(AudioContext);
  const musics = JSON.parse(localStorage.getItem('recentPlayed')) || [];

  function handlePlayList(vid) {
    const globalPlayIndex = _.findIndex(musics, (item) => item.vid === vid);
    dispatch(
      changeMusicVideo(musics, globalPlayIndex === -1 ? 0 : globalPlayIndex),
    );
    dispatch(setPlaying(true));
  }
  function renderRecentPlayed() {
    return musics.map((item) => (
      <RecentPlayedItem
        key={item.vid}
        {...item}
        handlePlayList={handlePlayList}
      />
    ));
  }

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('HomeScreen.recent_played_name'),
      path: '',
    },
  ];

  useEffect(() => {
    document.title = `${t('HomeScreen.recent_played_name')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  return (
    <Layout>
      <RecentPlayedWrapper
        name={t('HomeScreen.recent_played_name')}
        thumbnail={`${BASE_URL}/music/get-music-thumb/${musics[0].vid}`}
        totalTracks={musics.length}
        breadcumbs={breadcumbs}
        handlePlayAll={handlePlayList}
      >
        <RecentPlayList>{renderRecentPlayed()}</RecentPlayList>
      </RecentPlayedWrapper>
    </Layout>
  );
}
