import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { LoadingContext } from '../../../contexts/LoadingContext';

GlobalLoading.propTypes = {
  showLoading: PropTypes.bool,
};

GlobalLoading.defaultProps = {
  showLoading: false,
};

function GlobalLoading({ showLoading }) {
  const { state } = useContext(LoadingContext);
  return showLoading || state ? (
    <div className="ipl-progress-indicator" id="ipl-progress-indicator">
      <div className="ipl-progress-indicator-head">
        <div className="first-indicator" />
        <div className="second-indicator" />
      </div>
    </div>
  ) : null;
}

export default GlobalLoading;
