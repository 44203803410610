import { getBase64 } from '../utils';
import axiosClient from './axiosClient';

export const api = {
  getNewMusic: async (sort, limit, start) => {
    const { data } = await axiosClient.get(
      `/api/v1/new-music?limit=${limit}&sort=${sort}&start=${start}`,
    );
    return data;
  },
  getFeaturedMusics: async () => {
    const { data } = await axiosClient.get('/api/v1/featured-music');
    return data;
  },
  getTrendMusics: async (limit, start, sort) => {
    const { data } = await axiosClient.get(
      `/api/v1/trends?limit=${limit}&start=${start}&sort=${sort}`,
    );
    return data;
  },
  getSlides: async () => {
    const { data } = await axiosClient.get('/api/v1/slides');
    return data;
  },
  getGenres: async (limit, start) => {
    const { data } = await axiosClient.get(
      `/api/v1/genres?limit=${limit}&start=${start}`,
    );
    return data;
  },
  getGenreInfos: async (id) => {
    const { data } = await axiosClient.get(`/api/v1/genres/${id}`);
    return data;
  },
  getArtists: async (limit, start) => {
    const { data } = await axiosClient.get(
      `/api/v1/artists?limit=${limit}&start=${start}`,
    );
    return data;
  },
  getMusicsFromArtist: async (id, limit) => {
    const { data } = await axiosClient.get(
      `/api/v1/musics-by-artist?artistId=${id}&limit=${limit}`,
    );
    return data;
  },
  getArtistInfos: async (id) => {
    const { data } = await axiosClient.get(`/api/v1/artists/${id}`);
    return data;
  },
  getTopChart: async () => {
    const { data } = await axiosClient.get('/api/v1/top-chart');
    return data;
  },
  getTopChartByCountry: async (country, limit, start, sort) => {
    const { data } = await axiosClient.get(
      `/api/v1/top-chart/${country.toUpperCase()}?limit=${limit}&start=${start}&sort=${sort}`,
    );
    return data;
  },
  getAlbums: async (limit) => {
    const { data } = await axiosClient.get(`/api/v1/albums?limit=${limit}`);
    return data;
  },
  getDetailsAlbum: async (vid) => {
    const { data } = await axiosClient.get(`/api/v1/albums/${vid}`);
    return data;
  },
  getDetailsVideo: async (vid) => {
    const { data } = await axiosClient.get(
      `/audio/youtube-get-info?${getBase64(`q=${vid}`)}`,
    );
    return data;
  },
  getSuggestedVideos: async (vid) => {
    const { data } = await axiosClient.get(
      `/music/suggestion-videos?vid=${vid}`,
    );
    return data;
  },
  getSuggestionsSearch: async (value) => {
    const { data } = await axiosClient.get(
      `/music/get-suggestions-search?keyword=${value}`,
    );
    return data;
  },
  getSearchResults: async (value, limit) => {
    const { data } = await axiosClient.get(
      `/audio/youtube-get-info?${getBase64(`q=${value}`)}&limit=${limit}`,
    );
    return data;
  },
  like: async (body) => {
    const { data } = await axiosClient.post('/music/favorites/add', body);
    return data;
  },
  likedStatus: async ({ uid, vid }) => {
    const { data } = await axiosClient.get(
      `/music/liked-status?uid=${uid}&vid=${vid}`,
    );
    return data;
  },
  getPlayList: async (uid) => {
    const { data } = await axiosClient.get(`/music/playlist?uid=${uid}`);
    return data;
  },
  getAddedMusic: async ({ uid, vid }) => {
    const { data } = await axiosClient.get(
      `/music/playlist/added-music?uid=${uid}&vid=${vid}`,
    );
    return data;
  },
  addPlayList: async (body) => {
    const { data } = await axiosClient.post('/music/playlist/add', body);
    return data;
  },
  deletePlayList: async (body) => {
    const { data } = await axiosClient.post('/music/playlist/delete', body);
    return data;
  },
  saveToPlaylist: async (body) => {
    const { data } = await axiosClient.post('/music/playlist/save-music', body);
    return data;
  },
  deleteFromPlaylist: async (body) => {
    const { data } = await axiosClient.post(
      '/music/playlist/delete-music',
      body,
    );
    return data;
  },
  getMusicsFromPlaylist: async (playlistId) => {
    const { data } = await axiosClient.get(
      `/music/playlist/musics?playlistId=${playlistId}`,
    );
    return data;
  },
  getFavoritesMusic: async ({ uid, limit }) => {
    const { data } = await axiosClient.get(
      `/music/favorites?uid=${uid}&limit=${limit}`,
    );
    return data;
  },
  contact: async (body) => {
    const { data } = await axiosClient.post('/api/v1/contact', body);
    return data;
  },
};
