/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function PlayingWrapper({ children, total }) {
  const { t } = useTranslation();
  return (
    <section
      css={css`
        > ul {
          width: 100%;
          padding: 0px 15px;
          margin-top: 30px;
        }
      `}
      className="row row--grid"
    >
      <div className="col-12">
        <div className="main__title">
          <h2>{t('PlayQueueScreen.heading')}</h2>
          {total > 5 ? (
            <Link className="main__link" to="/playing-queue">
              {t('HomeScreen.see_all')}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
              </svg>
            </Link>
          ) : null}
        </div>
      </div>
      {children}
    </section>
  );
}
