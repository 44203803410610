/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { api } from '../../../../api';
import { YT_THUMB_URL } from '../../../../config';
import { durationCalc } from '../../../../utils';

export default function PlayQueueItem({
  channel,
  vid,
  title,
  duration,
  playlistId,
  handlePlayList,
  isPlaylistMusic,
  isPlayQueue,
  saveDeletedMusic,
}) {
  async function handleDeleteMusic() {
    try {
      const response = await api.deleteFromPlaylist({
        playlistId,
        vid,
      });
      saveDeletedMusic(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <li className="single-item">
      <div
        data-title="1. Got What I Got"
        data-artist="Jason Aldean"
        data-img={YT_THUMB_URL + vid + '/mqdefault.jpg'}
        className="single-item__cover"
      >
        <Link to={`/music?vid=${vid}`}>
          <img src={YT_THUMB_URL + vid + '/mqdefault.jpg'} alt="" />
        </Link>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
        </svg>
        <svg
          css={css`
            cursor: pointer;
          `}
          onClick={() => handlePlayList(vid)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M16,2a3,3,0,0,0-3,3V19a3,3,0,0,0,6,0V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-2,0V5a1,1,0,0,1,2,0ZM8,2A3,3,0,0,0,5,5V19a3,3,0,0,0,6,0V5A3,3,0,0,0,8,2ZM9,19a1,1,0,0,1-2,0V5A1,1,0,0,1,9,5Z" />
        </svg>
      </div>
      <div
        css={css`
          @media only screen and (max-width: 600px) {
            width: 130px;
          }
        `}
        className="single-item__title"
      >
        <h4>
          <Link to={`/music?vid=${vid}`}>{title}</Link>
        </h4>
        <span>{channel}</span>
      </div>
      {isPlaylistMusic ? (
        <div
          onClick={handleDeleteMusic}
          css={css`
            background-color: rgb(231 40 40 / 82%);
            cursor: pointer;
            transition: 0.2s;
            &:hover {
              background-color: rgb(255 19 19);
            }
            svg {
              width: 12px;
              fill: #ffffff;
            }
          `}
          className="single-item__add"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 19"
            className="cf-icon-svg"
          >
            <path d="M11.383 13.644A1.03 1.03 0 0 1 9.928 15.1L6 11.172 2.072 15.1a1.03 1.03 0 1 1-1.455-1.456l3.928-3.928L.617 5.79a1.03 1.03 0 1 1 1.455-1.456L6 8.261l3.928-3.928a1.03 1.03 0 0 1 1.455 1.456L7.455 9.716z" />
          </svg>
        </div>
      ) : isPlayQueue ? (
        <div className="single-item__add">
          <svg
            css={css`
              cursor: pointer;
            `}
            onClick={() => handlePlayList(vid)}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
          </svg>
        </div>
      ) : (
        <div className="single-item__add">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
          </svg>
        </div>
      )}
      <a href="#/" className="single-item__export">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z" />
        </svg>
      </a>
      <span className="single-item__time">{durationCalc(duration)}</span>
    </li>
  );
}
