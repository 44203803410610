import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Menu() {
  const { t } = useTranslation();

  return (
    <nav className="header__nav">
      <Link to="/profile">{t('HomeScreen.profile_name')}</Link>
      <Link to="/contact">{t('HomeScreen.contact_name')}</Link>
    </nav>
  );
}
