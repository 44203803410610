/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

export default function SearchWrapper({ title, children }) {
  return (
    <section className="row">
      <div className="col-12">
        <div
          css={css`
            margin-top: 40px;
            @media only screen and (max-width: 600px) {
              margin-top: 10px;
              h2 {
                font-size: 20px;
              }
            }
          `}
          className="main__title"
        >
          <h2>{title}</h2>
        </div>
      </div>
      {children}
    </section>
  );
}
