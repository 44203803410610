/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  function handleChangeLanguage({ target: { value } }) {
    i18n.changeLanguage(value);
  }
  return (
    <select
      onClick={handleChangeLanguage}
      css={css`
        color: #b4bbc0;
        display: block;
        width: 110px;
        padding: 6px 20px;
        font-size: 15px;
        line-height: 1.5;
        background-clip: padding-box;
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='lightgrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-position-x: 100%;
        background-position-y: 5px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        background-color: transparent;
        border: 1px solid rgb(205, 205, 205);
        background-repeat: no-repeat;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        margin-left: 10px;
      `}
      className="language-switcher"
      name=""
      id=""
    >
      <option value="en">English</option>
      <option value="fr">Français</option>
      <option value="ja">日本</option>
      <option value="kr">한국어</option>
      <option value="vi">Tiếng Việt</option>
    </select>
  );
}
