/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { STRAPI_URI } from '../../../config';

export default function ArtistInfos({ Name, subInfo, url }) {
  return (
    <div className="article__content">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          @media only screen and (min-width: 768px) {
            flex-direction: row;
          }
        `}
        className="article__artist"
      >
        <img
          css={css`
            height: auto;
            width: 200px !important;
            margin-right: 20px;
            border-radius: 12px;
          `}
          src={`${STRAPI_URI}${url}`}
          alt={Name}
        />
        <div
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          `}
        >
          <span
            css={css`
              color: #ffffff;
            `}
          >
            {subInfo}
          </span>
          <h3
            css={css`
              color: #ffffff;
            `}
          >
            {Name}
          </h3>
        </div>
      </div>
    </div>
  );
}
