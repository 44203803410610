import _ from 'lodash';
import React, { useContext } from 'react';
import { changeMusicVideo, setPlaying } from '../../../actions';
import { AudioContext } from '../../../contexts/AudioContext';
import PlayingQueue from './PlayingQueue';
import PlayQueueItem from './PlayingQueue/PlayingQueueItem';

export default function PlayingWrapper() {
  const { dispatch } = useContext(AudioContext);
  function handlePlayList(musics, vid) {
    const globalPlayIndex = _.findIndex(musics, (item) => item.vid === vid);
    dispatch(
      changeMusicVideo(musics, globalPlayIndex === -1 ? 0 : globalPlayIndex),
    );
    dispatch(setPlaying(true));
  }

  function renderPlayingQueue() {
    const musics = (JSON.parse(localStorage.getItem('playQueue')) || []).slice(
      0,
      5,
    );
    return musics.map((item) => (
      <PlayQueueItem
        key={item.vid}
        {...item}
        handlePlayList={() => handlePlayList(musics, item.vid)}
      />
    ));
  }

  function renderRecentPlayed() {
    const musics = (
      JSON.parse(localStorage.getItem('recentPlayed')) || []
    ).slice(0, 5);
    return musics.map((item) => (
      <PlayQueueItem
        key={item.vid}
        {...item}
        handlePlayList={() => handlePlayList(musics, item.vid)}
      />
    ));
  }
  return (
    <div className="tab-content">
      <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
        <div className="row row--grid">
          <PlayingQueue name="Playing Queue" path="/playing-queue">
            {renderPlayingQueue()}
          </PlayingQueue>
          <PlayingQueue name="Recent Played" path="/recent-played">
            {renderRecentPlayed()}
          </PlayingQueue>
        </div>
      </div>
    </div>
  );
}
