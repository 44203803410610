/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import useClickOutside from '../../../../../utils/useClickOutside';
import useEscKeydown from '../../../../../utils/useEscKeydown';

SearchSuggestions.propTypes = {
  searchSuggested: PropTypes.array,
};

SearchSuggestions.defaultProps = {
  searchSuggested: [],
};

export default function SearchSuggestions({
  searchSuggested,
  handleCloseSearchSuggestion,
}) {
  const searchSuggestionRef = useRef(null);

  useEscKeydown(() => {
    handleCloseSearchSuggestion();
  });

  useClickOutside(searchSuggestionRef, () => {
    handleCloseSearchSuggestion();
  });

  function renderSearchSuggest() {
    return _.map(searchSuggested, (item) => (
      <li key={item} onClick={() => handleCloseSearchSuggestion(item)}>
        <Link to={`/search?keyword=${item}`}>{item}</Link>
      </li>
    ));
  }
  return (
    <ul
      ref={searchSuggestionRef}
      css={css`
        position: absolute;
        background-color: white;
        width: 100%;
        text-align: left;
        list-style: none;
        box-shadow: 0 15px 30px -10px rgb(0 0 0 / 10%);
        transform: translateY(-3px);
        border-radius: 2px 2px 4px 4px;
        li {
          padding: 8px 13px;
          transition: 0.2s;
          &:hover {
            background-color: #f1f1f1;
          }
          a {
            color: #333;
            display: block;
          }
        }
      `}
    >
      {renderSearchSuggest()}
    </ul>
  );
}
