/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { api } from '../../../api';
import { FORM_SUBMITTING_STATUS } from '../../../config';

export default function ContactForm() {
  const alert = useAlert();
  const { t } = useTranslation();
  const [loadingStatus, setLoadingStatus] = useState(
    FORM_SUBMITTING_STATUS.none,
  );

  async function handleSubmitContact(values, { resetForm }) {
    setLoadingStatus(FORM_SUBMITTING_STATUS.processing);
    try {
      const { code } = await api.contact(values);
      if (code === 0) {
        setTimeout(() => {
          setLoadingStatus(FORM_SUBMITTING_STATUS.done);
        }, 2000);
        setTimeout(() => {
          setLoadingStatus(FORM_SUBMITTING_STATUS.none);
          resetForm({});
        }, 3500);
      }
    } catch (error) {
      setTimeout(() => {
        setLoadingStatus(FORM_SUBMITTING_STATUS.none);
        alert.error(t('ContactScreen.send_error'));
      }, 3500);
    }
  }

  const contactSchema = Yup.object().shape({
    fullName: Yup.string().required(t('ContactScreen.name_required_msg')),
    email: Yup.string()
      .email(t('ContactScreen.email_invalid_msg'))
      .required(t('ContactScreen.email_required_msg')),
    subject: Yup.string().required(t('ContactScreen.subject_required_msg')),
    message: Yup.string().required(t('ContactScreen.message_required_msg')),
  });

  return (
    <div className="col-12 col-lg-7 col-xl-8">
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          subject: '',
          message: '',
        }}
        validationSchema={contactSchema}
        onSubmit={handleSubmitContact}
      >
        {({ handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            className="sign__form sign__form--contacts"
          >
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="sign__group">
                  <Field name="fullName">
                    {({ field }) => (
                      <input
                        type="text"
                        name="fullName"
                        className="sign__input"
                        placeholder="Name"
                        {...field}
                      />
                    )}
                  </Field>
                  <span className="text-error">
                    <ErrorMessage name="fullName" />
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="sign__group">
                  <Field name="email">
                    {({ field }) => (
                      <input
                        type="email"
                        name="email"
                        className="sign__input"
                        placeholder="Email"
                        {...field}
                      />
                    )}
                  </Field>
                  <span className="text-error">
                    <ErrorMessage name="email" />
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="sign__group">
                  <Field name="subject">
                    {({ field }) => (
                      <input
                        type="text"
                        name="subject"
                        className="sign__input"
                        placeholder="Subject"
                        {...field}
                      />
                    )}
                  </Field>
                  <span className="text-error">
                    <ErrorMessage name="subject" />
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="sign__group">
                  <Field name="message">
                    {({ field }) => (
                      <textarea
                        name="message"
                        className="sign__textarea"
                        placeholder="Type your message"
                        {...field}
                      />
                    )}
                  </Field>
                  <span className="text-error">
                    <ErrorMessage name="message" />
                  </span>
                </div>
              </div>
              <div className="col-12 col-xl-3">
                <button
                  css={css`
                    svg {
                      width: 18px;
                      height: 18px;
                      margin-right: 6px;
                      -webkit-animation: rotating 2.6s linear infinite;
                      -moz-animation: rotating 2.6s linear infinite;
                      -ms-animation: rotating 2.6s linear infinite;
                      -o-animation: rotating 2.6s linear infinite;
                      animation: rotating 2.6s linear infinite;
                    }
                  `}
                  type="submit"
                  className="sign__btn"
                >
                  {FORM_SUBMITTING_STATUS.processing === loadingStatus ? (
                    <>
                      <svg
                        className="rotating"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="92px"
                        height="92px"
                        viewBox="0 0 92 92"
                        enableBackground="new 0 0 92 92"
                        xmlSpace="preserve"
                      >
                        <path
                          id="XMLID_93_"
                          d="M46,25.7c-2.2,0-4-1.8-4-4V4.5c0-2.2,1.8-4,4-4s4,1.8,4,4v17.2C50,23.9,48.2,25.7,46,25.7z M34.1,29.6
	c1.8-1.3,2.2-3.8,0.9-5.6L24.8,10.1c-1.3-1.8-3.8-2.2-5.6-0.9c-1.8,1.3-2.2,3.8-0.9,5.6l10.1,13.9c0.8,1.1,2,1.6,3.2,1.6
	C32.5,30.4,33.4,30.1,34.1,29.6z M26.7,39.7c0.7-2.1-0.5-4.4-2.6-5L7.7,29.4c-2.1-0.7-4.4,0.5-5,2.6C2,34,3.2,36.3,5.3,37l16.4,5.3
	c0.4,0.1,0.8,0.2,1.2,0.2C24.6,42.5,26.1,41.4,26.7,39.7z M7.7,62.6l16.4-5.3c2.1-0.7,3.3-2.9,2.6-5c-0.7-2.1-2.9-3.2-5-2.6L5.3,55
	c-2.1,0.7-3.3,2.9-2.6,5c0.5,1.7,2.1,2.8,3.8,2.8C6.9,62.8,7.3,62.8,7.7,62.6z M24.8,81.9L35,68c1.3-1.8,0.9-4.3-0.9-5.6
	s-4.3-0.9-5.6,0.9L18.4,77.2c-1.3,1.8-0.9,4.3,0.9,5.6c0.7,0.5,1.5,0.8,2.3,0.8C22.8,83.6,24,83,24.8,81.9z M50,87.5V70.3
	c0-2.2-1.8-4-4-4s-4,1.8-4,4v17.2c0,2.2,1.8,4,4,4S50,89.7,50,87.5z M72.8,82.8c1.8-1.3,2.2-3.8,0.9-5.6L63.5,63.3
	c-1.3-1.8-3.8-2.2-5.6-0.9c-1.8,1.3-2.2,3.8-0.9,5.6l10.1,13.9c0.8,1.1,2,1.6,3.2,1.6C71.2,83.6,72.1,83.3,72.8,82.8z M89.3,60.1
	c0.7-2.1-0.5-4.4-2.6-5l-16.4-5.3c-2.1-0.7-4.4,0.5-5,2.6c-0.7,2.1,0.5,4.4,2.6,5l16.4,5.3c0.4,0.1,0.8,0.2,1.2,0.2
	C87.2,62.8,88.8,61.7,89.3,60.1z M70.3,42.3L86.7,37c2.1-0.7,3.3-2.9,2.6-5c-0.7-2.1-2.9-3.2-5-2.6l-16.4,5.3
	c-2.1,0.7-3.3,2.9-2.6,5c0.5,1.7,2.1,2.8,3.8,2.8C69.5,42.5,69.9,42.4,70.3,42.3z M63.5,28.7l10.1-13.9c1.3-1.8,0.9-4.3-0.9-5.6
	c-1.8-1.3-4.3-0.9-5.6,0.9L57,24c-1.3,1.8-0.9,4.3,0.9,5.6c0.7,0.5,1.5,0.8,2.3,0.8C61.5,30.4,62.7,29.8,63.5,28.7z"
                        />
                      </svg>
                      <span>{t('ContactScreen.sending')}</span>
                    </>
                  ) : FORM_SUBMITTING_STATUS.done === loadingStatus ? (
                    <>
                      <i
                        css={css`
                          font-size: 18px;
                          margin-right: 6px;
                        `}
                        className="las la-check"
                      />
                      <span>{t('ContactScreen.sent')}</span>
                    </>
                  ) : (
                    <span>{t('ContactScreen.send')}</span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
