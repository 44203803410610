import i18n from 'i18next';

import langVi from './locales/vi_VN/translations.json';
import langEn from './locales/en_US/translations.json';

i18n.init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: { translation: langEn },
    vi: { translation: langVi },
  },
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
