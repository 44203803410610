/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { api } from '../api';
import ArtistWrapper from '../components/HomePageComponents/ArtistWrapper';
import ArtistItem from '../components/HomePageComponents/ArtistWrapper/ArtistItem';
import ArtistList from '../components/HomePageComponents/ArtistWrapper/ArtistList';
import Sliders from '../components/HomePageComponents/Slides';
import Breadcrumb from '../components/Shared/BreadCrumb';
import CircleLoading from '../components/Shared/CircleLoading';
import Layout from '../components/Shared/Layout';
import {
  DEFAULT_PAGE_ITEM_LIMIT,
  DEFAULT_PAGE_ITEM_START_INDEX,
} from '../config';

export default function ArtistPage() {
  const { t } = useTranslation();
  const [artists, setArtists] = useState([]);
  const [slides, setSlides] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  function renderArtists() {
    return artists.map((item) => <ArtistItem key={item.id} {...item} />);
  }

  async function handleLoadMore() {
    try {
      const response = await api.getArtists(
        12,
        DEFAULT_PAGE_ITEM_LIMIT + pageIndex * 12,
      );
      setArtists(
        [...artists, ...response.data].filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
        ),
      );
      setPageIndex(pageIndex + 1);
      if (response.data.length < 12) {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getNewMusics() {
      try {
        const response = await api.getArtists(
          DEFAULT_PAGE_ITEM_LIMIT,
          DEFAULT_PAGE_ITEM_START_INDEX,
        );
        setArtists(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getNewMusics();
  }, []);

  useEffect(() => {
    async function getSlides() {
      try {
        const response = await api.getSlides();
        setSlides(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getSlides();
  }, []);

  useEffect(() => {
    document.title = `${t('ArtistScreen.heading')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('ArtistScreen.heading'),
      path: '',
    },
  ];

  return (
    <Layout>
      <div className="row row--grid">
        <div className="col-12">
          <Breadcrumb breadcumbs={breadcumbs} />
        </div>
      </div>
      <br />
      <Sliders slides={slides} />
      <InfiniteScroll
        dataLength={artists.length}
        next={handleLoadMore}
        hasMore={hasMore}
        loader={
          <CircleLoading
            showLoading
            css={css`
              position: relative;
              height: 280px;
              background-color: transparent;
            `}
          />
        }
      >
        <ArtistWrapper isDetailsListing>
          <ArtistList>{renderArtists()}</ArtistList>
        </ArtistWrapper>
      </InfiniteScroll>
    </Layout>
  );
}
