import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  changeMusicVideo,
  hideLoading,
  setPlaying,
  showLoading,
} from '../actions';
import { api } from '../api';
import MusicDetailsComponents from '../components/MusicDetailsComponents';
import Layout from '../components/Shared/Layout';
import { AudioContext } from '../contexts/AudioContext';
import { LoadingContext } from '../contexts/LoadingContext';
import { getQueryString } from '../utils';

export default function MusicDetailsPage() {
  const { t } = useTranslation();
  const { dispatch } = useContext(LoadingContext);
  const { dispatch: audioDispatch } = useContext(AudioContext);
  const [videoInfos, setVideoInfos] = useState({});
  const [suggestedVideos, setSuggestedVideos] = useState([]);
  const location = useLocation();

  const { vid } = getQueryString(location.search);

  function handleChangeMusicVideo(musics) {
    audioDispatch(changeMusicVideo(musics));
    audioDispatch(setPlaying(true));
  }

  const memoizedCallback = useCallback(handleChangeMusicVideo, [vid]);

  useEffect(() => {
    document.title = `${videoInfos.title || ''} - ${t('HomeScreen.app_name')}`;
  }, [videoInfos.title]);

  useEffect(() => {
    async function handleFetchInfos() {
      try {
        dispatch(showLoading());
        const { infos } = await api.getDetailsVideo(vid);
        setVideoInfos(infos);
        dispatch(hideLoading());
      } catch (error) {
        console.log(error);
      }
    }
    handleFetchInfos();
  }, [vid]);

  useEffect(() => {
    async function handleGetSuggestedVideos() {
      try {
        setSuggestedVideos([]);
        const { data } = await api.getSuggestedVideos(vid);
        setSuggestedVideos(data);
      } catch (error) {
        console.log(error);
      }
    }
    handleGetSuggestedVideos();
  }, [vid]);

  return (
    <Layout>
      <MusicDetailsComponents
        videoInfos={videoInfos}
        suggestedVideos={suggestedVideos}
        changeMusicVideo={memoizedCallback}
      />
    </Layout>
  );
}
