import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { changeMusicVideo, setPlaying } from '../actions';
import { api } from '../api';
import FavoriteWrapper from '../components/PlayQueueComponents/PlayQueueWrapper';
import MusicItem from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueItem';
import FavoriteList from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueList';
import Layout from '../components/Shared/Layout';
import { BASE_URL } from '../config';
import { AudioContext } from '../contexts/AudioContext';
import { getQueryString } from '../utils';

export default function PlaylistDetailsPage() {
  const { t } = useTranslation();
  const { dispatch } = useContext(AudioContext);
  const [musics, setMusics] = useState([]);
  const location = useLocation();
  const { id, name } = getQueryString(location.search);

  function handlePlayList(vid) {
    const globalPlayIndex = _.findIndex(musics, (item) => item.vid === vid);
    dispatch(
      changeMusicVideo(musics, globalPlayIndex === -1 ? 0 : globalPlayIndex),
    );
    dispatch(setPlaying(true));
  }

  function saveDeletedMusic(vid) {
    const newData = musics.filter((item) => item.vid !== vid);
    setMusics([...newData]);
  }

  function renderMusicItems() {
    return musics.map((item) => (
      <MusicItem
        key={item.vid}
        {...item}
        isPlaylistMusic
        playlistId={id}
        saveDeletedMusic={saveDeletedMusic}
        handlePlayList={handlePlayList}
      />
    ));
  }

  useEffect(() => {
    async function handleGetMusicsFromPlaylist() {
      try {
        const response = await api.getMusicsFromPlaylist(id);
        setMusics(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    handleGetMusicsFromPlaylist();
  }, [id]);

  useEffect(() => {
    document.title = `${name} - ${t('HomeScreen.app_name')}`;
  }, []);

  const breadcumbs = [
    {
      name: 'Trang chủ',
      path: '/',
    },
    {
      name,
      path: '',
    },
  ];

  return (
    <Layout>
      <FavoriteWrapper
        name={name}
        thumbnail={`${BASE_URL}/music/get-music-thumb/${musics[0]?.vid}`}
        totalTracks={musics.length}
        breadcumbs={breadcumbs}
        handlePlayAll={handlePlayList}
      >
        <FavoriteList>{renderMusicItems()}</FavoriteList>
      </FavoriteWrapper>
    </Layout>
  );
}
