import _ from 'lodash';
import React, { Fragment } from 'react';

export default function NewReleaseFilter({ sorts, handleSortList }) {
  function renderSort() {
    return _.map(sorts, ({ name, sortValue, defaultChecked }) => (
      <Fragment key={name}>
        <input
          type="radio"
          name="grade"
          id={name}
          defaultChecked={defaultChecked ? 'checked' : ''}
        />
        <label onClick={() => handleSortList(sortValue)} htmlFor={name}>
          {name}
        </label>
      </Fragment>
    ));
  }
  return (
    <div className="col-12 col-md-6">
      <div className="main__filter">
        <div className="slider-radio">
          {renderSort()}
          <div
            className="sliding-piece"
            style={{
              position: 'absolute',
              transition:
                'width 0.2s ease 0s, height 0.2s ease 0s, top 0.2s ease 0s, left 0.2s ease 0s',
              opacity: 0,
              left: '5px',
              top: '5px',
              width: '88.75px',
              height: '30px',
            }}
          />
        </div>
      </div>
    </div>
  );
}
