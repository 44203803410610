/** @jsxRuntime classic */
/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

CircleLoading.propTypes = {
  showLoading: PropTypes.bool,
};

CircleLoading.defaultProps = {
  showLoading: false,
};

function CircleLoading({ showLoading, ...props }) {
  return showLoading ? (
    <ClassNames>
      {({ css, cx }) => (
        <div
          css={css`
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 99;
            background-color: #000;
          `}
          className={cx(
            css`
              &::-webkit-scrollbar {
                display: none;
              }
            `,
          )}
          {...props}
        >
          <div
            css={css`
              width: 40px;
              height: 40px;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -13px;
              margin-left: -13px;
              border-radius: 60px;
              animation: loader 0.8s linear infinite;
              -webkit-animation: loader 0.8s linear infinite;
              border: 4px solid #f44336;
            `}
          />
        </div>
      )}
    </ClassNames>
  ) : null;
}

export default CircleLoading;
