/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { YT_THUMB_URL } from '../../../../config';
import { durationCalc, formatViewValue } from '../../../../utils';

export default function FeaturedItem({
  Channel,
  Name,
  Vid,
  Views,
  Duration,
  changeMusicVideo,
  Date,
}) {
  return (
    <div className="col-md-4 col-12">
      <div
        css={css`
          &:hover .play-icon {
            opacity: 1;
            transform: scale(1);
          }
        `}
        className="event"
        data-bg={YT_THUMB_URL + Vid + '/mqdefault.jpg'}
        style={{
          background: `url(${
            YT_THUMB_URL + Vid + '/mqdefault.jpg'
          }) center center / cover no-repeat`,
        }}
      >
        <div
          onClick={() =>
            changeMusicVideo([
              {
                vid: Vid,
                title: Name,
                channel: Channel,
              },
            ])
          }
          className="play-icon"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
          </svg>
        </div>
        <span className="event__date">{moment(Date).format('LLL')}</span>
        <span className="event__time">
          <svg
            css={css`
              width: 14px;
              height: auto;
              fill: silver;
              margin-right: 5px;
            `}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M20,13.18V11A8,8,0,0,0,4,11v2.18A3,3,0,0,0,2,16v2a3,3,0,0,0,3,3H8a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H6V11a6,6,0,0,1,12,0v2H16a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h3a3,3,0,0,0,3-3V16A3,3,0,0,0,20,13.18ZM7,15v4H5a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1Zm13,3a1,1,0,0,1-1,1H17V15h2a1,1,0,0,1,1,1Z" />
          </svg>
          {`${formatViewValue(Views)} | ${durationCalc(Duration)}`}
        </span>
        <h3 className="event__title">
          <Link to={`/music?vid=${Vid}`}>{Name}</Link>
        </h3>
        <p className="event__address">{Channel}</p>
      </div>
    </div>
  );
}
