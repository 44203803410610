import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeMusicVideo, setPlaying } from '../actions';
import PlayQueueWrapper from '../components/PlayQueueComponents/PlayQueueWrapper';
import PlayQueueItem from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueItem';
import PlayQueueList from '../components/PlayQueueComponents/PlayQueueWrapper/PlayQueueList';
import Layout from '../components/Shared/Layout';
import { BASE_URL } from '../config';
import { AudioContext } from '../contexts/AudioContext';

export default function PlayQueuePage() {
  const { t } = useTranslation();
  const { dispatch } = useContext(AudioContext);
  const musics = JSON.parse(localStorage.getItem('playQueue')) || [];

  function handlePlayList(vid) {
    const globalPlayIndex = _.findIndex(musics, (item) => item.vid === vid);
    dispatch(
      changeMusicVideo(musics, globalPlayIndex === -1 ? 0 : globalPlayIndex),
    );
    dispatch(setPlaying(true));
  }

  function renderPlayQueue() {
    return musics.map((item) => (
      <PlayQueueItem
        key={item.vid}
        {...item}
        isPlayQueue
        handlePlayList={handlePlayList}
      />
    ));
  }

  useEffect(() => {
    document.title = `${t('HomeScreen.play_queue_name')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('HomeScreen.play_queue_name'),
      path: '',
    },
  ];

  return (
    <Layout>
      <PlayQueueWrapper
        name={t('HomeScreen.play_queue_name')}
        thumbnail={`${BASE_URL}/music/get-music-thumb/${musics[0].vid}`}
        totalTracks={musics.length}
        breadcumbs={breadcumbs}
        handlePlayAll={handlePlayList}
      >
        <PlayQueueList>{renderPlayQueue()}</PlayQueueList>
      </PlayQueueWrapper>
    </Layout>
  );
}
