/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { hideModal, showModal } from '../../../../actions';
import { api } from '../../../../api';
import { ModalContext } from '../../../../contexts/ModalContext';
import ConfirmDialog from '../../../Shared/ConfirmDialog';

export default function PlaylistItem({ id, name, saveDeletedPlaylist }) {
  const { dispatch } = useContext(ModalContext);

  function handleCloseDialog() {
    return dispatch(hideModal());
  }

  function handleShowConfirmDialog() {
    dispatch(
      showModal(
        <ConfirmDialog
          playlistName={name}
          handleConfirm={handleDeletePlaylist}
          handleCloseDialog={handleCloseDialog}
        />,
      ),
    );
  }

  async function handleDeletePlaylist() {
    try {
      const response = await api.deletePlayList({
        id,
      });
      saveDeletedPlaylist(response.data);
      handleCloseDialog();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <li
      css={css`
        height: 40px;
        svg {
          width: 12px;
          fill: #ffffff;
        }
      `}
      className="single-item"
    >
      <div
        css={css`
          @media only screen and (max-width: 600px) {
            width: 130px;
          }
          margin-left: 0px;
          h4 {
            font-size: 18px;
          }
        `}
        className="single-item__title"
      >
        <h4>
          <Link to={`/playlists/?id=${id}&name=${name}`}>{name}</Link>
        </h4>
      </div>
      <div
        onClick={handleShowConfirmDialog}
        css={css`
          background-color: rgb(231 40 40 / 82%);
          cursor: pointer;
          transition: 0.2s;
          &:hover {
            background-color: rgb(255 19 19);
          }
        `}
        className="single-item__add"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 19"
          className="cf-icon-svg"
        >
          <path d="M11.383 13.644A1.03 1.03 0 0 1 9.928 15.1L6 11.172 2.072 15.1a1.03 1.03 0 1 1-1.455-1.456l3.928-3.928L.617 5.79a1.03 1.03 0 1 1 1.455-1.456L6 8.261l3.928-3.928a1.03 1.03 0 0 1 1.455 1.456L7.455 9.716z" />
        </svg>
      </div>
    </li>
  );
}
