/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../api';
import { sleep } from '../../../utils';

export default function SaveMusicModal({
  uid,
  vid,
  title,
  channel,
  duration,
  hideModal,
}) {
  const [showForm, setShowForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [playList, setPlaylist] = useState('');
  const [playslists, setPlaylists] = useState([]);
  const [addedPlaylist, setAddedPlaylist] = useState([]);
  const [savingItem, setSavingItem] = useState('');

  const { t } = useTranslation();

  async function handleAddPlaylist(event) {
    event.preventDefault();
    if (!playList)
      return setErrorMsg(t('DetailsMvScreen.playlist_name_required'));
    try {
      const response = await api.addPlayList({
        uid,
        name: playList,
      });
      setPlaylists([...playslists, response.data]);
      setShowForm(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSaveToPlaylist({ checked, playlistId }) {
    try {
      if (checked) {
        await sleep(200);
        setSavingItem(playlistId);
      }
      await api.saveToPlaylist({
        playlistId,
        uid,
        vid,
        channel,
        title,
        duration,
      });
      let newData = [];
      if (addedPlaylist.includes(playlistId)) {
        newData = addedPlaylist.filter((item) => item !== playlistId);
      } else {
        newData = [...addedPlaylist, playlistId];
      }
      setAddedPlaylist(newData);
      await sleep(500);
      setSavingItem('');
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange({ target: { value } }) {
    if (errorMsg && value) setErrorMsg('');
    if (!errorMsg && !value)
      setErrorMsg(t('DetailsMvScreen.playlist_name_required'));
    setPlaylist(value);
  }

  function handleShowForm() {
    setShowForm(true);
  }

  function renderPlaylists() {
    return _.map(playslists, ({ id, name }) => (
      <li key={id}>
        <label htmlFor={id} className="control control--checkbox">
          {name}{' '}
          {`${
            savingItem === id ? ` ${t('DetailsMvScreen.add_to_playlist')}` : ''
          }`}
          <input
            checked={addedPlaylist.includes(id)}
            onChange={({ target: { checked } }) =>
              handleSaveToPlaylist({
                checked,
                playlistId: id,
              })
            }
            id={id}
            type="checkbox"
          />
          <div className="control__indicator" />
        </label>
      </li>
    ));
  }

  useEffect(() => {
    async function getPLaylistRequest() {
      try {
        const response = await api.getPlayList(uid);
        setPlaylists(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getPLaylistRequest();
  }, [uid]);

  useEffect(() => {
    async function getAddedMusicRequest() {
      try {
        const response = await api.getAddedMusic({
          uid,
          vid,
        });
        setAddedPlaylist(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getAddedMusicRequest();
  }, [uid, vid]);

  return (
    <div
      css={css`
        background-color: rgb(255, 255, 255);
        min-width: 350px;
        border-radius: 4px;
        position: fixed;
        z-index: 9999;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
      `}
      className="save-music"
    >
      <div
        css={css`
          display: flex;
          padding: 10px 16px;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgb(234, 240, 249);
          margin-bottom: 6px;
        `}
        className="save-music-heading"
      >
        <h6
          css={css`
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #4c4c4c;
          `}
        >
          {t('DetailsMvScreen.save_to')}
        </h6>
        <i
          onClick={hideModal}
          css={css`
            color: #4c4c4c;
            font-size: 22px;
            cursor: pointer;
            &:hover {
              color: #333;
            }
          `}
          className="las la-times"
        />
      </div>
      <ul
        css={css`
          margin-left: 0px;
          margin-bottom: 6px;
          li {
            padding: 6px 16px;
          }
        `}
        className="save-music-album-list"
      >
        {renderPlaylists()}
      </ul>
      {showForm ? (
        <form
          onSubmit={handleAddPlaylist}
          css={css`
            margin-top: 10px;
            padding: 0 16px;
          `}
          className="create-playlist"
        >
          <input
            onChange={handleChange}
            css={css`
              width: 100%;
              border: 1px solid #c7c7c7;
              color: #333;
              font-size: 16px;
              padding: 8px 16px;
              border-radius: 2px;
              &:focus {
                border-color: #747474;
              }
              @media only screen and (max-width: 600px) {
                font-size: 18px;
              }
            `}
            type="text"
            placeholder={t('DetailsMvScreen.input_placeholder')}
          />
          {errorMsg ? (
            <span
              css={css`
                color: #e10000;
                margin-top: 4px;
                display: block;
              `}
            >
              {errorMsg}
            </span>
          ) : null}
          <button
            css={css`
              font-size: 18px;
              font-weight: 400;
              text-transform: uppercase;
              color: #2aa1c0;
              margin: 16px 0;
              display: block;
              margin-left: auto;
              &:hover {
                color: #166478;
              }
            `}
            type="submit"
          >
            {t('DetailsMvScreen.create')}
          </button>
        </form>
      ) : (
        <div
          onClick={handleShowForm}
          css={css`
            padding: 10px 16px;
            border-top: 1px solid #c7c7c7;
            margin-top: 0px;
            cursor: pointer;
            text-align: center;
            &:hover {
              i,
              span {
                color: #2aa1c0;
              }
            }
          `}
          className="save-music-footer"
        >
          <i
            css={css`
              font-size: 22px;
              vertical-align: middle;
              margin: 0 8px;
            `}
            className="las la-plus"
          />
          <span> {t('DetailsMvScreen.create_new_playlist')}</span>
        </div>
      )}
    </div>
  );
}
