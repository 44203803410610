/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { hideModal, showModal } from '../../../../actions';
import { api } from '../../../../api';
import { AuthContext } from '../../../../contexts/AuthContext';
import { ModalContext } from '../../../../contexts/ModalContext';
import SaveMusicModal from '../../SaveMusicModal';

export default function VideoActions({ vid, channel, title, duration }) {
  const alert = useAlert();
  const [checkedLoop, setCheckedLoop] = useState(false);
  const [checkedAutoNext, setCheckedAutoNext] = useState(false);
  const { dispatch } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const [actionStatus, setActionStatus] = useState({
    liked: false,
    queue: false,
  });
  const { t } = useTranslation();

  async function handleLike() {
    try {
      await api.like({
        uid: user.uid,
        vid,
        channel,
        title,
        duration,
      });
      setActionStatus({
        ...actionStatus,
        liked: !actionStatus.liked,
      });
    } catch (error) {
      alert.error(t('Common.require_login_first'));
      console.log(error);
    }
  }

  function handleAddToQueue() {
    const storedData = JSON.parse(localStorage.getItem('playQueue')) || [];
    const index = _.findIndex(storedData, (item) => item.vid === vid);
    let newData = [];
    if (index === -1) {
      newData = [...storedData, { vid, channel, title, duration }];
    } else {
      newData = Object.assign(storedData, {
        [index]: { ...storedData[index], title },
      });
    }
    localStorage.setItem('playQueue', JSON.stringify(newData));
    setActionStatus({
      ...actionStatus,
      queue: true,
    });
  }

  function showSaveToPlayListModal() {
    if (!user.uid) return alert.error(t('Common.require_login_first'));
    dispatch(
      showModal(
        <SaveMusicModal
          uid={user.uid}
          vid={vid}
          title={title}
          channel={channel}
          duration={duration}
          hideModal={() => dispatch(hideModal())}
        />,
      ),
    );
  }

  const loopThisVideo = () => {
    console.log('loop this video');
  };

  const autoNextVideo = () => {
    console.log('auto next this video');
  };

  useEffect(() => {
    async function handleCheckLikedStatus() {
      try {
        const { data } = await api.likedStatus({
          vid,
          uid: user.uid,
        });
        const storedData = JSON.parse(localStorage.getItem('playQueue')) || [];
        const index = _.findIndex(storedData, (item) => item.vid === vid);
        setActionStatus({
          liked: data === 0,
          queue: index === -1 ? false : true,
        });
      } catch (error) {
        console.log(error);
      }
    }
    handleCheckLikedStatus();
  }, [user.uid, vid]);

  const { liked, queue } = actionStatus;

  const handleChange = () => {
    setCheckedLoop(!checkedLoop);
  };

  const handleChangeAutoNext = () => {
    setCheckedAutoNext(!checkedAutoNext);
  };

  return (
    <div
      css={css`
        display: flex;
        margin-top: 16px;
        svg {
          @media only screen and (max-width: 600px) {
            width: 22px;
            height: 22px;
          }
        }
        > div {
          cursor: pointer;
          transition: 0.2s;
          svg {
            fill: #bbc0c0;
          }
          & {
            margin-right: 25px;
            span {
              font-size: 16px;
              color: #bbc0c0;
              margin-left: 6px;
              vertical-align: middle;
              &::selection {
                background-color: #16151a;
                color: #ffffff;
              }
              @media only screen and (max-width: 600px) {
                font-size: 14px;
                white-space: nowrap;
              }
            }
          }
          &:hover,
          &.active {
            svg {
              fill: #ffffff;
              path {
                fill: #ffffff;
              }
            }
            span {
              color: #ffffff;
            }
          }
        }
      `}
      className="music-actions"
    >
      <div className="left__action">
        <div
          onClick={handleLike}
          className={`item__action music-actions-like${liked ? ' active' : ''}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            viewBox="0 0 1024 1024"
          >
            <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0 1 42.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z" />
          </svg>
          <span>{liked ? 'Liked' : 'Like'}</span>
        </div>
        <div
          onClick={handleAddToQueue}
          className={`item__action music-actions-queue${
            queue ? ' active' : ''
          }`}
        >
          <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <defs>
              {/* eslint-disable-next-line react/no-danger */}
              <style
                dangerouslySetInnerHTML={{ __html: '.cls-1{fill:none;}' }}
              />
            </defs>
            <rect x={10} y={6} width={18} height={2} />
            <rect x={10} y={12} width={18} height={2} />
            <rect x={15} y={18} width={13} height={2} />
            <rect x={10} y={24} width={18} height={2} />
            <polygon points="4 14 11 19 4 24 4 14" />
            <rect
              id="_Transparent_Rectangle_"
              data-name="<Transparent Rectangle>"
              className="cls-1"
              width={32}
              height={32}
            />
          </svg>
          <span>{queue ? 'Added to Queue' : 'Add to Queue'}</span>
        </div>
        <div
          onClick={showSaveToPlayListModal}
          className="item__action music-actions-playlist"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
          >
            <path d="M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z" />
          </svg>
          <span>Add to PlayList</span>
        </div>
      </div>
      <div className="right__action">
        <div
          onClick={loopThisVideo}
          className="item__action music-actions-playlist"
        >
          <label className="control control--checkbox checkbox--details">
            <span>Loop this video</span>
            <input
              checked={checkedLoop}
              onChange={handleChange}
              type="checkbox"
            />
            <div className="control__indicator" />
          </label>
        </div>
        <div
          onClick={autoNextVideo}
          className="item__action music-actions-playlist"
        >
          <label className="control control--checkbox checkbox--details">
            <span>Auto play next video</span>
            <input
              checked={checkedAutoNext}
              onChange={handleChangeAutoNext}
              type="checkbox"
            />
            <div className="control__indicator" />
          </label>
        </div>
      </div>
    </div>
  );
}
