/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { STRAPI_URI } from '../../../../config';
import { getQueryString } from '../../../../utils';

export default function SlideItem({ Description, Title, url, Destination }) {
  const { t } = useTranslation();
  const { v } = getQueryString(
    Destination.replace('https://www.youtube.com/watch', ''),
  );
  return (
    <div
      className="hero__slide"
      data-bg={`${STRAPI_URI}${url}`}
      style={{
        background: `url(${STRAPI_URI}${url}) center center / cover no-repeat`,
      }}
    >
      <h2 className="hero__title">{Title}</h2>
      <p className="hero__text">{Description}</p>
      <div className="hero__btns">
        <div
          css={css`
            > a {
              color: #ffffff;
            }
          `}
          className="hero__btn hero__btn--video open-video"
        >
          <Link to={`/music?vid=${v}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16,10.27,11,7.38A2,2,0,0,0,8,9.11v5.78a2,2,0,0,0,1,1.73,2,2,0,0,0,2,0l5-2.89a2,2,0,0,0,0-3.46ZM15,12l-5,2.89V9.11L15,12ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
            </svg>{' '}
            {t('HomeScreen.watch_video')}
          </Link>
        </div>
      </div>
    </div>
  );
}
