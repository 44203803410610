/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import * as types from '../actions/types';

export const AudioContext = React.createContext();

const INITIAL_STATE = {
  musics: JSON.parse(sessionStorage.getItem('musics')) || [],
  isPlaying: false,
  globalPlayIndex: 0,
};
function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CHANGE_MUSIC:
      const { musics, globalPlayIndex } = action.payload;
      console.log(action.payload);
      return {
        ...state,
        musics,
        globalPlayIndex,
      };
    case types.SET_PLAYING:
      return {
        ...state,
        isPlaying: action.payload,
      };
    default:
      throw new Error('Unexpected action');
  }
}

AudioProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

function AudioProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <AudioContext.Provider value={{ state, dispatch }}>
      {children}
    </AudioContext.Provider>
  );
}

export default AudioProvider;
