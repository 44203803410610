import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoginComponent from '../components/Auth/LoginComponent';
import BreadCrumb from '../components/Shared/BreadCrumb';
import Layout from '../components/Shared/Layout';

function LoginPage() {
  const { t } = useTranslation();
  const breadcumbs = [
    {
      name: t('HomeScreen.home_name'),
      path: '/',
    },
    {
      name: t('HomeScreen.signin_name'),
      path: '',
    },
  ];

  useEffect(() => {
    document.title = `${t('HomeScreen.signin_name')} - ${t(
      'HomeScreen.app_name',
    )}`;
  }, []);

  return (
    <Layout>
      <BreadCrumb breadcumbs={breadcumbs} />
      <LoginComponent />
    </Layout>
  );
}

export default LoginPage;
