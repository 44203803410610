/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export default function ConfirmDialog({
  playlistName,
  handleCloseDialog,
  handleConfirm,
}) {
  const { t } = useTranslation();
  return (
    <div
      css={css`
        background-color: rgb(255, 255, 255);
        width: 400px;
        border-radius: 4px;
        position: fixed;
        z-index: 9999;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
        button {
          padding: 6px 20px;
          border-radius: 4px;
          font-size: 16px;
          transition: 0.2s;
        }
      `}
      className="confirm-dialog"
    >
      <h4
        css={css`
          margin: 0px;
          font-weight: 500;
          font-size: 1.25rem;
          line-height: 1.6;
          letter-spacing: 0.0075em;
          padding: 16px;
        `}
      >
        {t('FavoritesScreen.confirm_heading')}
      </h4>
      <p
        css={css`
          margin: 0px;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.00938em;
          color: rgba(0, 0, 0, 0.6);
          padding: 0px 16px 20px 16px;
        `}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('FavoritesScreen.body', {
            playlistName,
            interpolation: { escapeValue: false },
          }),
        }}
      />
      <div
        css={css`
          padding: 0px 16px 16px 0px;
          display: flex;
          justify-content: flex-end;
        `}
        className="confirm-dialog-footer"
      >
        <button
          css={css`
            border: 1.5px solid #55acee;
            color: #55acee;
            margin-right: 6px;
            &:hover {
              color: #4ca2e2;
              border-color: #4ca2e2;
            }
          `}
          onClick={handleCloseDialog}
          type="button"
        >
          {t('Common.no')}
        </button>
        <button
          css={css`
            color: #fff;
            background-color: #55acee;
            &:hover {
              background-color: #4ca2e2;
            }
          `}
          onClick={handleConfirm}
          type="button"
        >
          {t('Common.yes')}
        </button>
      </div>
    </div>
  );
}
