/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

export default function ChartMusicList({ children }) {
  return (
    <ul
      css={css`
        margin-top: 30px;
      `}
      className="main__list"
    >
      {children}
    </ul>
  );
}
