import React, { useState } from 'react';
import GlobalLoading from '../../Shared/GlobalLoading';
import ModalPortal from '../../Shared/ModalPortal';
import Footer from '../Footer';
import NavBar from '../NavBar';
import SideBar from '../SideBar';

export default function Layout({ children }) {
  const [isShownSidebar, setIsShownSideBar] = useState(false);
  function setSideBar() {
    setIsShownSideBar(!isShownSidebar);
  }

  return (
    <>
      <NavBar isShownSidebar={isShownSidebar} setSideBar={setSideBar} />
      <ModalPortal />
      <SideBar isShownSidebar={isShownSidebar} setSideBar={setSideBar} />
      <GlobalLoading />
      <main className="main">
        <div className="container-fluid">{children}</div>
      </main>
      <Footer />
    </>
  );
}
