/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import AudioPlayerLib from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { animated } from 'react-spring';
import { changeMusicVideo } from '../../../actions';
import { YT_THUMB_URL } from '../../../config';
import { AudioContext } from '../../../contexts/AudioContext';
import { getMp3Url, durationCalc } from '../../../utils';

const AudioPlayer = () => {
  const { state, dispatch } = useContext(AudioContext);
  const { musics, globalPlayIndex } = state;
  const audioRef = useRef(new Audio());
  const [audioUrl, setAudioUrl] = useState('');
  const [playingIndex, setPlayingIndex] = useState(0);
  const [isShowPlayer, setShowPlayer] = useState(musics.length ? true : false);
  const { t } = useTranslation();

  function handleSetPlayer() {
    setShowPlayer(!isShowPlayer);
  }

  function handleClosePlayer() {
    dispatch(changeMusicVideo([]));
    audioRef.current.pause();
  }

  function handleForward() {
    if (playingIndex < musics.length - 1) {
      return setPlayingIndex(playingIndex + 1);
    }
    setPlayingIndex(0);
  }

  function handleRewind() {
    if (playingIndex === 0) return setPlayingIndex(musics.length - 1);
    setPlayingIndex(playingIndex - 1);
  }

  function handleSaveRecentPlayed(duration) {
    const oldData = JSON.parse(localStorage.getItem('recentPlayed')) || [];
    const newData = [
      ...new Map(
        [
          ...oldData,
          {
            vid: musics[playingIndex]?.vid,
            title: musics[playingIndex]?.title,
            channel: musics[playingIndex]?.channel,
            duration,
            created: Date.now(),
          },
        ].map((item) => [item.vid, item]),
      ).values(),
    ];
    localStorage.setItem('recentPlayed', JSON.stringify(newData));
  }

  useEffect(() => {
    setPlayingIndex(globalPlayIndex);
  }, [globalPlayIndex, musics.length]);

  useEffect(() => {
    if (musics.length && musics[playingIndex]?.vid) {
      setAudioUrl(getMp3Url(musics[playingIndex]?.vid));
    }
  }, [musics, playingIndex]);

  return musics.length ? (
    <Fragment>
      <div
        css={css`
          button,
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover svg {
              fill: #25a56a;
            }
          }
          .rhap_volume-button[aria-label='Mute'],
          .rhap_volume-button[aria-label='Unmute'] {
            &:before {
              content: ${t('Player.mute')};
              background-color: #222227;
              box-shadow: none;
              color: #fff;
              font-size: 14px !important;
              padding: 4px 6px;
              top: -20px;
              opacity: 0;
              position: absolute;
              transition: 0.1s;
              z-index: -1;
              white-space: nowrap;
            }
            &:hover:before {
              opacity: 1;
              top: -100%;
            }
          }
          .rhap_volume-button[aria-label='Unmute']:before {
            content: ${t('Player.un_mute')};
          }
          .playing-queue-icon[aria-label='Playing-queue'] {
            position: absolute;
            bottom: 0px;
            left: auto;
            right: 0px;
            &:before {
              content: ${t('Player.playing_queue')};
              background-color: #222227;
              box-shadow: none;
              color: #fff;
              font-size: 14px !important;
              padding: 4px 6px;
              top: -20px;
              opacity: 0;
              position: absolute;
              transition: 0.1s;
              z-index: -1;
              white-space: nowrap;
            }
            &:hover:before {
              opacity: 1;
              top: -100%;
            }
          }
        `}
        className={`player ${isShowPlayer ? 'player--active' : ''}`}
        style={{ maxWidth: 600, margin: 'auto' }}
      >
        <i
          onClick={() => setShowPlayer(false)}
          css={css`
            color: #fff;
            font-size: 24px;
            position: absolute;
            top: 10px;
            right: 50px;
            cursor: pointer;
            &:hover {
              color: #25a56a;
            }
          `}
          className="las la-minus"
        />
        <i
          onClick={handleClosePlayer}
          css={css`
            color: #fff;
            font-size: 24px;
            position: absolute;
            top: 10px;
            right: 16px;
            cursor: pointer;
            &:hover {
              color: #25a56a;
            }
          `}
          className="las la-times"
        />
        <div className="player__cover">
          <img
            // eslint-disable-next-line no-unsafe-optional-chaining
            src={YT_THUMB_URL + musics[playingIndex]?.vid + '/mqdefault.jpg'}
            alt=""
          />
        </div>
        <div className="player__content">
          <span className="player__track">
            <b className="player__title">{musics[playingIndex]?.title}</b> –{' '}
            <span className="player__artist">
              {musics[playingIndex]?.channel}
            </span>
          </span>
          <AudioPlayerLib
            style={{ borderRadius: '1rem', backgroundColor: 'transparent' }}
            autoPlay
            loop={false}
            src={audioUrl}
            onLoadedData={({ target }) => {
              document.querySelector('.rhap_time.rhap_total-time').innerHTML =
                durationCalc(target.duration).replace(/^00:/, '');
            }}
            onEnded={({ target: { duration } }) => {
              handleSaveRecentPlayed(duration);
              if (musics[playingIndex + 1]) setPlayingIndex(playingIndex + 1);
            }}
            footer={
              <Link
                to="/playing-queue"
                className="playing-queue-icon"
                aria-label="Playing-queue"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M15,13H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm0-4H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
                </svg>
              </Link>
            }
            customIcons={{
              play: (
                <animated.div>
                  <button type="button">
                    <svg
                      className="icon--not-pressed"
                      role="presentation"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
                    </svg>
                  </button>
                </animated.div>
              ),
              pause: (
                <animated.div>
                  <button type="button">
                    <svg
                      className="icon--pressed"
                      role="presentation"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16,2a3,3,0,0,0-3,3V19a3,3,0,0,0,6,0V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-2,0V5a1,1,0,0,1,2,0ZM8,2A3,3,0,0,0,5,5V19a3,3,0,0,0,6,0V5A3,3,0,0,0,8,2ZM9,19a1,1,0,0,1-2,0V5A1,1,0,0,1,9,5Z" />
                    </svg>
                  </button>
                </animated.div>
              ),
              rewind: (
                <button
                  onClick={handleRewind}
                  type="button"
                  className="plyr__control plyr__control--prev"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M20.28,3.43a3.23,3.23,0,0,0-3.29,0L8,8.84V6A3,3,0,0,0,2,6V18a3,3,0,0,0,6,0V15.16l9,5.37a3.28,3.28,0,0,0,1.68.47,3.24,3.24,0,0,0,1.61-.43,3.38,3.38,0,0,0,1.72-3V6.42A3.38,3.38,0,0,0,20.28,3.43ZM6,18a1,1,0,0,1-2,0V6A1,1,0,0,1,6,6Zm14-.42a1.4,1.4,0,0,1-.71,1.25,1.23,1.23,0,0,1-1.28,0L8.68,13.23a1.45,1.45,0,0,1,0-2.46L18,5.19A1.23,1.23,0,0,1,18.67,5a1.29,1.29,0,0,1,.62.17A1.4,1.4,0,0,1,20,6.42Z" />
                  </svg>
                </button>
              ),
              forward: (
                <button
                  onClick={handleForward}
                  type="button"
                  className="plyr__control plyr__control--next"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M19,3a3,3,0,0,0-3,3V8.84L7,3.47a3.21,3.21,0,0,0-3.29,0A3.38,3.38,0,0,0,2,6.42V17.58a3.38,3.38,0,0,0,1.72,3A3.24,3.24,0,0,0,5.33,21,3.28,3.28,0,0,0,7,20.53l9-5.37V18a3,3,0,0,0,6,0V6A3,3,0,0,0,19,3ZM15.32,13.23,6,18.81a1.23,1.23,0,0,1-1.28,0A1.4,1.4,0,0,1,4,17.58V6.42a1.4,1.4,0,0,1,.71-1.25A1.29,1.29,0,0,1,5.33,5,1.23,1.23,0,0,1,6,5.19l9.33,5.58a1.45,1.45,0,0,1,0,2.46ZM20,18a1,1,0,0,1-2,0V6a1,1,0,0,1,2,0Z" />
                  </svg>
                </button>
              ),
              volume: (
                <svg
                  className="icon--not-pressed"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12.43,4.1a1,1,0,0,0-1,.12L6.65,8H3A1,1,0,0,0,2,9v6a1,1,0,0,0,1,1H6.65l4.73,3.78A1,1,0,0,0,12,20a.91.91,0,0,0,.43-.1A1,1,0,0,0,13,19V5A1,1,0,0,0,12.43,4.1ZM11,16.92l-3.38-2.7A1,1,0,0,0,7,14H4V10H7a1,1,0,0,0,.62-.22L11,7.08ZM19.66,6.34a1,1,0,0,0-1.42,1.42,6,6,0,0,1-.38,8.84,1,1,0,0,0,.64,1.76,1,1,0,0,0,.64-.23,8,8,0,0,0,.52-11.79ZM16.83,9.17a1,1,0,1,0-1.42,1.42A2,2,0,0,1,16,12a2,2,0,0,1-.71,1.53,1,1,0,0,0-.13,1.41,1,1,0,0,0,1.41.12A4,4,0,0,0,18,12,4.06,4.06,0,0,0,16.83,9.17Z" />
                </svg>
              ),
              volumeMute: (
                <svg
                  className="icon--pressed"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12.43,4.1a1,1,0,0,0-1,.12L6.65,8H3A1,1,0,0,0,2,9v6a1,1,0,0,0,1,1H6.65l4.73,3.78A1,1,0,0,0,12,20a.91.91,0,0,0,.43-.1A1,1,0,0,0,13,19V5A1,1,0,0,0,12.43,4.1ZM11,16.92l-3.38-2.7A1,1,0,0,0,7,14H4V10H7a1,1,0,0,0,.62-.22L11,7.08ZM19.91,12l1.8-1.79a1,1,0,0,0-1.42-1.42l-1.79,1.8-1.79-1.8a1,1,0,0,0-1.42,1.42L17.09,12l-1.8,1.79a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l1.79-1.8,1.79,1.8a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
                </svg>
              ),
            }}
          />
        </div>
      </div>
      <button
        onClick={handleSetPlayer}
        className="player__btn player__btn--active"
        type="button"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z" />
        </svg>{' '}
        {t('Player.player')}
      </button>
    </Fragment>
  ) : null;
};

export default AudioPlayer;
